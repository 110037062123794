<template>
  <div class="fotter">
    <img class="fotter-logo" src="../assets/logo_header.svg" alt="uranao">
    <ul class="fotter__list">
      <li class="fotter__list__item"><router-link to="/">Home</router-link></li>
      <li class="fotter__list__item"><a href="https://astrostar.company">運営会社</a></li>
      <li class="fotter__list__item"><a href="https://astrostar.company/privacy-policy.html">プライバシーポリシー</a></li>
      <li class="fotter__list__item"><a href="https://astrostar.company/contact/index.php">お問い合わせ</a></li>
    </ul>
    <ul>
      <li><a href="https://twitter.com/uranao_tarot" target="_blank"><img src="../assets/ico-tw.svg" alt="twitter"></a></li>
      <li><a href="https://www.instagram.com/uranao_tarot/?igshid=NTc4MTIwNjQ2YQ%3D%3D" target="_blank"><img src="../assets/ico-ig.svg" alt="Instagram"></a></li>
    </ul>
    <p class="copyright">uranao©︎2023</p>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 8px;
  letter-spacing: 2px;
  line-height: 1.7;
}
a {
  color: #838383;
}
.fotter {
  position: relative;
  z-index: 10;
  padding: 28px 20px;
  text-align: left;
}
.fotter-logo {
  max-width: 95px;
  margin: 12px 6px;
}
.fotter__list {
  text-align: left;
  margin-bottom: 12px;
}
.fotter__list__item {
  font-size: 12px;
  margin-bottom: 4px;
  border-right: solid 1px #E9E9E9;
  padding-right: 1em;
  line-height: 1.5;
}
.fotter__list__item:last-of-type {
  border: none;
}
.copyright {
  font-size: 12px;
  border-top: solid 1px #E9E9E9;
  padding: 8px;
  color: #838383;
  text-align: center;
  margin-top: 24px;
}

</style>
