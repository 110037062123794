<template>
  <div v-bind:class ={spheader:isActive} class="pc-none sp-header-sub">
    <h1 class="sp-header-logo">
      <img src="../assets/logo_header.svg" alt="uranao">
    </h1>
    <button v-on:click="change" class="sp-hamburger">
      <img src="../assets/ico-hamburger.svg" alt="menu-button">
    </button>
  </div>
  <div v-bind:class ={headershow:isActive}  class="header pc-header sp-header">
    <div class="pc-header__inner, sp-header__inner">
      <div v-bind:class ={show:isActive} class="pc-header__inner__menu, sp-header__inner__menu">
        <button v-on:click="change" class="sp-menu-close pc-none">
          <img src="../assets/ico-close.svg" alt="close-button">
        </button>
        <nav class="pc-nav sp-nav">
          <h1 class="pc-header-logo">
            <img src="../assets/logo_header.svg" alt="uranao">
          </h1>
          <ul class="header-list">
            <li v-on:click="change" class="header-list__item"><router-link to="/">ホーム</router-link></li>
            <li v-on:click="change" class="header-list__item"><router-link to="/divination">今日の運勢（タロット占い）</router-link></li>
            <li v-on:click="change" class="header-list__item"><a href="https://astrostar.company/index.html#company">運営会社</a></li>
            <li v-on:click="change" class="header-list__item"><a href="https://astrostar.company/contact/index.php">お問い合わせ</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div> 
</template>

<script>
  export default {
    data(){
      return {
        isActive: false,
      }
    },
    methods: {
      change:function(){
        this.isActive = !this.isActive;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #363636;
  text-decoration: none;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
.header-list__item {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.7;
  letter-spacing: 2px;
}


@media screen and (min-width:769px) {
  .header-list {
    display: flex;
  }
  .header-list li {
    margin: 0 16px;
  }
  .pc-nav {
    display: flex;
    align-items: center;
    padding: 12px 32px;
    background: #fff;
    border-bottom: 1px solid #E9E9E9;
    height: 35px;
  }
  .pc-header-logo {
    margin-right: 12px;
  }
  .pc-header-logo img {
    vertical-align: middle;
  }
}

@media screen and (max-width:768px) {
  .sp-header-sub {
    z-index: 16;
    position: relative;
  }
  .sp-hamburger{
    position: fixed;
    top: 16px;
    left: 20px;
  }
  .sp-header-logo {
    position: fixed;
    top: 0;
    height: 60px;
    display: flex;
    justify-content: center;
    background: #fff;
    border-bottom: 1px solid #E9E9E9;
  }
  .header-list__item {
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 1.7;
}
  .sp-header-logo img {
    max-width: 102px;
  }
  .sp-nav {
    top: 0;
    right: 0;
    height: 100vh;
    padding: 100px 20px 400px;
  }
  h1 {
    width: 100%;
    margin-bottom: 40px;
  }
  h1 img {
    max-width: 140px;
  }
  .sp-header__inner {
    width: 100%;
    height: 100vh;
  }
  .sp-header__inner__menu {
    background-color: #fff;
    position: relative;
    left: -100vw;
    position: relative;
  }
  .sp-menu-close {
    position: absolute;
    right: 16px;
    top: 16px;
  }
  .header-list {
    width: 100%;
  }
  .header-list__item {
    display: block;
    margin-bottom: 20px;
  }
}
.show {
  left: 0;
  z-index: 17;
  transition: all 0.3s 0s ease;
}
.headershow {
  z-index: 17;
}
.spheader {
  z-index: 1;
}
</style>
