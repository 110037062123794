import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import createGtag from 'vue-gtag'

createApp(App).use(
    createGtag,{
      id: "G-JZE368FYTN"
    }, router)
  


export default {
  name: 'MyComponent',
    setup() {
      const login = () => {
        createGtag('login', { method: 'Google' })
        }
        
        return {
          login
        }
      }
    }
  
createApp(App).use(router).mount('#app')
