<template>
    <div class="divination background-grad">
        <div class="background-grad-base">
            <section>
                <h1 class="page-title "><span>今日の運勢</span></h1>
                <div class="cardchoice-area">
                <ul class="card-list">
                <li>
                    <button>
                        <img v-bind:src="imgsUrl1" v-bind:style="cardReserve1" alt="テーマのカード" v-on:click.once="ramdam1($event),change1($event),btnActive()">
                    </button>
                    <p>{{ itemsName[0]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl2" v-bind:style="cardReserve2" alt="自分のカード" v-on:click.once="ramdam2($event),change2($event),btnActive()">
                    </button>
                    <p>{{ itemsName[1]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl3" v-bind:style="cardReserve3" alt="お金のカード" v-on:click.once="ramdam3($event),change3($event),btnActive()">
                    </button>
                    <p>{{ itemsName[2]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl4" v-bind:style="cardReserve4" alt="知識のカード" v-on:click.once="ramdam4($event),change4($event),btnActive()">
                    </button>
                    <p>{{ itemsName[3]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl5" v-bind:style="cardReserve5" alt="家族のカード" v-on:click.once="ramdam5($event),change5($event),btnActive()">
                    </button>
                    <p>{{ itemsName[4]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl6" v-bind:style="cardReserve6" alt="恋愛のカード" v-on:click.once="ramdam6($event),change6($event),btnActive()">
                    </button>
                    <p>{{ itemsName[5]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl7" v-bind:style="cardReserve7" alt="健康のカード" v-on:click.once="ramdam7($event),change7($event),btnActive()">
                    </button>
                    <p>{{ itemsName[6]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl8" v-bind:style="cardReserve8" alt="対人のカード" v-on:click.once="ramdam8($event),change8($event),btnActive()">
                    </button>
                    <p>{{ itemsName[7]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl9" v-bind:style="cardReserve9" alt="相続のカード" v-on:click.once="ramdam9($event),change9($event),btnActive()">
                    </button>
                    <p>{{ itemsName[8]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl10" v-bind:style="cardReserve10" alt="海外のカード" v-on:click.once="ramdam10($event),change10($event),btnActive()">
                    </button>
                    <p>{{ itemsName[9]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl11" v-bind:style="cardReserve11" alt="仕事のカード" v-on:click.once="ramdam11($event),change11($event),btnActive()">
                    </button>
                    <p>{{ itemsName[10]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl12" v-bind:style="cardReserve12" alt="友人のカード" v-on:click.once="ramdam12($event),change12($event),btnActive()">
                    </button>
                    <p>{{ itemsName[11]['name'] }}</p>
                </li>
                <li>
                    <button>
                        <img v-bind:src="imgsUrl13" v-bind:style="cardReserve13" alt="奉仕のカード" v-on:click.once="ramdam13($event),change13($event),btnActive()">
                    </button>
                    <p>{{ itemsName[12]['name'] }}</p>
                </li>
            </ul>
            <!--<p class="mb-1em">{{turn}}回カードをタップしてください</p>-->

            <button v-on:click="change" v-bind:disabled="isDisabled" class="btn-primary width-100p">
                <a href="#cardresult"><span class="btn-primary__txt">占い結果を見る</span></a>
            </button>
            <p v-bind:class ={snackshow:SnackIsActive}>カードをタップしてください</p>
            </div>
        <ul id="cardresult" v-bind:class ={resultshow:isActive} class="card-result">
            <li v-bind:class ={resultshow1:CardIsActive1} class="card-result__item1">
                <dl>
                    <dt>{{ itemsName[0]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl1" v-bind:style="cardReserve1" alt="テーマのカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName1 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText1 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow2:CardIsActive2} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[1]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl2" v-bind:style="cardReserve2" alt="自分のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName2 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText2 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow3:CardIsActive3} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[2]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl3" v-bind:style="cardReserve3" alt="お金のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName3 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText3 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow4:CardIsActive4} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[3]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl4" v-bind:style="cardReserve4" alt="知識のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName4 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText4 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow5:CardIsActive5} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[4]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl5" v-bind:style="cardReserve5" alt="家族のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName5 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText5 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow6:CardIsActive6} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[5]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl6" v-bind:style="cardReserve6" alt="恋愛のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName6 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText6 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow7:CardIsActive7} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[6]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl7" v-bind:style="cardReserve7" alt="健康のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName7 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText7 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow8:CardIsActive8} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[7]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl8" v-bind:style="cardReserve8" alt="対人のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName8 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText8 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow9:CardIsActive9} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[8]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl9" v-bind:style="cardReserve9" alt="相続のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName9 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText9 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow10:CardIsActive10} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[9]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl10" v-bind:style="cardReserve10" alt="海外のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName10 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText10 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow11:CardIsActive11} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[10]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl11" v-bind:style="cardReserve11" alt="仕事のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName11 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText11 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow12:CardIsActive12} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[11]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl12" v-bind:style="cardReserve12" alt="友人のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName12 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText12 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow13:CardIsActive13} class="card-result__item">
                <dl>
                    <dt>{{ itemsName[12]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li>
                                <img v-bind:src="imgsUrl13" v-bind:style="cardReserve13" alt="奉仕のカード">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName13 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText13 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
        </ul>
        </section>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                turn: 0,
                isActive: false,
                isDisabled: true,
                SnackIsActive: false,
                CardIsActive1: false,
                CardIsActive2: false,
                CardIsActive3: false,
                CardIsActive4: false,
                CardIsActive5: false,
                CardIsActive6: false,
                CardIsActive7: false,
                CardIsActive8: false,
                CardIsActive9: false,
                CardIsActive10: false,
                CardIsActive11: false,
                CardIsActive12: false,
                CardIsActive13: false,
                itemsName: [
                    {
                        no: 0,
                        name: 'テーマ',
                    },
                    {
                        no: 1,
                        name: '自分',
                    },
                    {
                        no: 2,
                        name: 'お金',
                    },
                    {
                        no: 3,
                        name: '知識',
                    },
                    {
                        no: 4,
                        name: '家族',
                    },                
                    {
                        no: 5,
                        name: '恋愛',
                    },
                    {
                        no: 6,
                        name: '健康',
                    },
                    {
                        no: 7,
                        name: '対人',
                    },
                    {
                        no: 8,
                        name: '相続',
                    },
                    {
                        no: 9,
                        name: '海外',
                    },
                    {
                        no: 10,
                        name: '仕事',
                    },
                    {
                        no: 11,
                        name: '友人',
                    },
                    {
                        no: 12,
                        name: '奉仕',
                    }
                ],
                cardReserve1: '',
                cardReserve2: '',
                cardReserve3: '',
                cardReserve4: '',
                cardReserve5: '',
                cardReserve6: '',
                cardReserve7: '',
                cardReserve8: '',
                cardReserve9: '',
                cardReserve10: '',
                cardReserve11: '',
                cardReserve12: '',
                cardReserve13: '',
                cardText1: 'カードを開いていないため結果はありません',
                cardText2: 'カードを開いていないため結果はありません',
                cardText3: 'カードを開いていないため結果はありません',
                cardText4: 'カードを開いていないため結果はありません',
                cardText5: 'カードを開いていないため結果はありません',
                cardText6: 'カードを開いていないため結果はありません',
                cardText7: 'カードを開いていないため結果はありません',
                cardText8: 'カードを開いていないため結果はありません',
                cardText9: 'カードを開いていないため結果はありません',
                cardText10: 'カードを開いていないため結果はありません',
                cardText11: 'カードを開いていないため結果はありません',
                cardText12: 'カードを開いていないため結果はありません',
                cardText13: 'カードを開いていないため結果はありません',
                imgsUrl1: require('@/assets/tarotcard_back.png'),
                imgsUrl2: require('@/assets/tarotcard_back.png'),
                imgsUrl3: require('@/assets/tarotcard_back.png'),
                imgsUrl4: require('@/assets/tarotcard_back.png'),
                imgsUrl5: require('@/assets/tarotcard_back.png'),
                imgsUrl6: require('@/assets/tarotcard_back.png'),
                imgsUrl7: require('@/assets/tarotcard_back.png'),
                imgsUrl8: require('@/assets/tarotcard_back.png'),
                imgsUrl9: require('@/assets/tarotcard_back.png'),
                imgsUrl10: require('@/assets/tarotcard_back.png'),
                imgsUrl11: require('@/assets/tarotcard_back.png'),
                imgsUrl12: require('@/assets/tarotcard_back.png'),
                imgsUrl13: require('@/assets/tarotcard_back.png'),
                imgsCard: [
                    {
                            no: 0,
                            imgUrl: require('@/assets/tarotcard_00.png'),
                            name: {
                                normal: '愚者（正位置）',
                                reserve: '愚者（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたは未知の可能性を前向きに、楽観的に捉えています。新しい環境に挑戦すると良いでしょう。結果までは保証されませんが、チャレンジする価値は大いにあります。',
                                '自分':'これまでの自分の殻を脱ぎ捨て、新たな自分に出会えそうです。',
                                'お金':'新しい視点のお金の稼ぎ方や自分の能力、価値観を見つけられるかもしれません。',
                                '知識':'いつもとは違う勉強や会話に挑戦すると良いでしょう。',
                                '家族':'家族と訪れたことのない冒険をしてみると良さそうです。',
                                '恋愛':'新しい恋の出会いや、お付き合いしている人とのハラハラするような出来事があるかもしれません。',
                                '健康':'いつものルーティンではない取り組みが吉となるでしょう。',
                                '対人':'知らない人がいるところに飛び込んでみると新しい発見がありそうです。',
                                '相続':'深い繋がりのある人の新たなる可能性の暗示があります。',
                                '海外':'海外旅行や外国語の勉強をすると新しい発見が得られそうです。',
                                '仕事':'転職、転勤のきっかけとなる出来事が訪れるかもしれません。',
                                '友人':'今まで関わったことのない環境や友人との良い出会いがありそうです。積極的に行動してみましょう。',
                                '奉仕':'愚者（正位置）の「奉仕」結果が入ります'
                            },
                            ResultReserve: {
                                'テーマ':'かなり無謀な賭けをしそうです。もしかしてまだ準備不足かもしれません。一度現実的なアプローチを考えてみると良いでしょう。',
                                '自分':'あなたは自分の方向性を見失っているかもしれません。それだけ自分に無茶な期待をしているようです。一度時間をおいて冷静になると良いでしょう。',
                                'お金':'あなたは無謀なお金の使い方や稼ぎ方を考えていそうです。もしくはあまり現実が見れていません。',
                                '知識':'今は無謀なことを考えすぎているかもしれません。',
                                '家族':'あなたの家族が今無謀なことをしていませんか？もしくはあなたが家族に対して無謀なことを要求している可能性があります。',
                                '恋愛':'敵わない恋に突っ込んでしまいそうです。一度冷静になりましょう。',
                                '健康':'無茶な生活習慣になりそうです。',
                                '対人':'周りの無茶振りで振り回されそうです。慌てずに冷静に対処しましょう。',
                                '相続':'深い繋がりのある人から、思っても見ない見返りを要求されるかもしれません。',
                                '海外':'海外旅行や専門的な勉強はあまり向かない日です。',
                                '仕事':'計画性のない仕事で空回りしそうです。',
                                '友人':'友人の中で自分が非常に浮いたような存在になりそうなので、発言や立ち回りには注意しましょう。',
                                '奉仕':'愚者（逆位置）の「奉仕」結果が入ります'
                            },
                    },
                    {
                            no: 1,
                            imgUrl: require('@/assets/tarotcard_01.png'),
                            name: {
                                normal: '魔術師（正位置）',
                                reserve: '魔術師（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたのユニークな個性を活かして新しい一歩を踏み出せそうです。今日は積極的に発言や行動をすると周りから注目を集めそうです。',
                                '自分':'自分らしさに自信を持てるでしょう。積極的に自分をアピールすると良さそうです。',
                                'お金':'新しいお金稼ぎをするための土台が整ったようです。また才能開花の予感。思い浮かぶ限りで新しい手法を試してみてください。',
                                '知識':'新しく勉強を始めるのにはうってつけです。自信を持って取り組みましょう。またあなたのコミュニケーション力も光りそうです。',
                                '家族':'家族や自宅で新しい取り組みを始めると面白いかもしれません。',
                                '恋愛':'片思いなら告白のチャンス。新しい出会いにも恵まれます。お付き合いしている人は個性的なデートが楽しめそう。',
                                '健康':'新しいルーティンを積極的に取り入れてみましょう。',
                                '対人':'個性的な人との出会いに恵まれます。その人から良い刺激をもらえそうなので積極的に交流してみましょう。',
                                '相続':'親密な中の人から新しい提案が生まれそう。もしくは新しい関係性に変化するかもしれません。',
                                '海外':'今まで行ったことのない場所へ積極的に訪れてみましょう。また外国語や専門的な勉強にも向いている日です。',
                                '仕事':'あなたのユニークさが注目を浴びそうです。自分に自信を持てるのでプレゼンには最適の日になるでしょう。',
                                '友人':'面白い友人と出会えるでしょう。ぜひ積極的に声をかけてみてください。',
                                '奉仕':'あなたの個性に周りが救われるかもしれません、見えないところで感謝されるかも。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたの個性がなかな活かしきれないかもしれません。自信過剰か自身がなさすぎるか、とにかく表立った発言は控えた方が良さそうです。',
                                '自分':'自分らしくいられなかったり、過剰に自分をアピールしたりするかもしれませんが、やりすぎには気をつけましょう。',
                                'お金':'まだ準備が整っていないようです。まずはやるべきことに集中しましょう。',
                                '知識':'自分の発言で空回りしてしまったり、コミュニケーションがうまく取りにくくなりそうです。勉強も捗りにくいので、まずは環境を整えましょう。',
                                '家族':'家族の中で悪目立ちするような人がいるかもしれません。',
                                '恋愛':'告白するのにはまだ準備が足りていません。空回りしやすいので、一旦落ち着きましょう。',
                                '健康':'新しく試そうとしてる健康法やルーティンはあまり合っていないかもしれません。もう少し必要な情報を調べたりして検討してみてください。',
                                '対人':'悪目立ちする人に対峙しそうです。ある意味で自分を振り返るきっかけにもなるかもしれません。',
                                '相続':'深い関係の人の自分本位な考えで振り回されそうです。もしくは自分がそのようにならないように気をつけましょう',
                                '海外':'行ったことのない場所への遠出や専門的な勉強をするのにはまだ準備が足りていないようです。まずは準備を整えましょう。',
                                '仕事':'新しく始めようとしたことはまだ準備が足りていません。もしくは自分の良さが活かせないのかもしれません。',
                                '友人':'行き過ぎたユニークな友人に振り回される可能性があります。',
                                '奉仕':'人助けだと思ってやったことが仇になりやすいです。まずは準備を整えてからにしましょう。'
                            },
                    },
                    {
                            no: 2,
                            imgUrl: require('@/assets/tarotcard_02.png'),
                            name: {
                                normal: '女教皇（正位置）',
                                reserve: '女教皇（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'全ての答えはあなたの中にあります。心を研ぎ澄まして自分の直感を信じてみて。今のあなたなら冷静に見極めることができるでしょう。',
                                '自分':'自分自身を冷静に捉えられています。人から見ると凛とした雰囲気に映るでしょう。',
                                'お金':'必要な能力やお金の使い道を冷静に見極められています。また、あなたの中に眠る潜在能力に気づくかもしれません。',
                                '知識':'冷静な判断ができる時。知らない分野の勉強をしてみると捗りそうです。',
                                '家族':'女性の家族が今中心になっています。何かを冷静に見つめているようです。',
                                '恋愛':'凛としたあなたでいられる時。大人で冷静な人との出会いや、お付き合いができそうです。',
                                '健康':'自分の健康についてしっかりと見ています。',
                                '対人':'冷静で物知りな人との出会いがありそうです。もしくはそのような人がキーマンになるかも。',
                                '相続':'深い付き合いのある人との関係性を冷静に見ています。その直感で正しいようです。',
                                '海外':'難しい専門知識の勉強に向いていそうです。遠く離れて、静かな環境だとより良いでしょう。',
                                '仕事':'あなたの冷静沈着な視点が評価されるかもしれません。感情ではなく理性、またあなたの持つ知識が役に立つでしょう。',
                                '友人':'凛としたミステリアスな友人との縁がありそうです。',
                                '奉仕':'あなたの感じる方法で誰かを助けると良いでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'感情的になり冷静な判断が出来なさそうです。今日は何かを決断したり、考えすぎることをやめた方が良さそう。',
                                '自分':'感情がぶれやすヒステリックになりやすいので、感情的になったら一度一呼吸おきましょう。',
                                'お金': 'イライラして散財する可能性があります。本当に必要なもの何かを冷静に判断しましょう。',
                                '知識':'勉強は捗りにくいかもしれません。人に感情的に当たらないようにコミュニケーションの取り方には注意してください。',
                                '家族':'家族の誰か、もしくはあなたが感情的になりやすいので気をつけましょう。',
                                '恋愛':'感情的に優位になりヒステリックになりやすいので、相手との意思疎通がうまくいきにくいでしょう。感情的になりそうなら一旦離れて落ち着いた方が良さそうです。',
                                '健康':'精神的に落ち着きがなくなりやすいので、あまり考えすぎずゆったりと過ごすことで安定するでしょう。',
                                '対人':'ヒステリックな相手と関わりそうです。お相手は自分の感情に囚われていて冷静な判断ができないため、一旦は距離を置いた方が良さそうです。',
                                '相続':'親密な距離の相手と感情的にぶつかりうまくいかないようです。相続や相続の揉め事には気をつけてください。',
                                '海外':'海外や外国などにおける分野で納得がいかずヒステリックに感じそうです。今日はそういう時期なんだと割り切りも必要かもしれません。',
                                '仕事':'感情的になり集中がしにくく仕事はあまり捗らないかもしれません。会議などでの感情的な言動には気をつけましょう。もしくは仕事上で感情的な女性に振り回される可能性があります。',
                                '友人':'自分や相手の感情的な振る舞いに困ることになりそう。感情的になっていると感じたら一旦相手の声も聞いてみて。',
                                '奉仕':'自分ばかり尽くした結果イライラしてしまいそう。自分の余裕が持てない場合は無理をして何かをする必要はないので、一旦考えたり無理に動かない方が良さそうです。'
                            },
                    },
                    {
                            no: 3,
                            imgUrl: require('@/assets/tarotcard_03.png'),
                            name: {
                                normal: '女帝（正位置）',
                                reserve: '女帝（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'豊かさを享受しそうです。何かが実ることになるので、それを大いに受け取りましょう。あなたの包容力が魅力として発揮できそうです。',
                                '自分':'あなたはとてもおおらかでゆとりがあり魅力的に見えるでしょう。優しい気持ちであるがままにいられます。',
                                'お金':'臨時収入に恵まれそうです。',
                                '知識':'今何か知識を身につけている最中であれば、今日何かの良い結果が出るかもしれません。',
                                '家族':'家族の中で母親的な存在の人に支えられるでしょう。その人はきっと心強い味方となります。',
                                '恋愛':'恋が成就しそうです。自分の思う良い結果が得られるでしょう。魅力的な状態なので、誰かからアプローチされる可能性も。',
                                '健康':'心の豊かさが健康に繋がるでしょう。非常に良い状態です。',
                                '対人':'魅力的な女性と縁がありそうです。もしくはあなたがそう見えるのかもしれません。対人運は良いでしょう。',
                                '相続':'相続が相続されたり、人から何かをもらえたり、懸賞に当たったり。お金や物を何かしら「もらえる」運があります。',
                                '海外':'なるべく遠い場所へ行くと何かを得られそうです。外国語や専門的な勉強を学ぶとより広がりが出るでしょう。',
                                '仕事':'あなたの魅力が評価され、仕事の報酬アップが望めるかもしれません。もしくは良い評判が広がるでしょう。',
                                '友人':'優しく女性的魅力のある友人に出会えたり、また友人との交流はあなたに良い影響を与えるでしょう。',
                                '奉仕':'あなたの心の余裕が自然と周りに良い影響を与えていくでしょう。ボランティア活動をするととても良い結果が出ます。'
                            },
                            ResultReserve: {
                                'テーマ':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '自分':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                'お金':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '知識':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '家族':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '恋愛':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '健康':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '対人':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '相続':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '海外':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '仕事':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '友人':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '奉仕':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。'
                            },
                    },
                    {
                            no: 4,
                            imgUrl: require('@/assets/tarotcard_04.png'),
                            name: {
                                normal: '皇帝（正位置）',
                                reserve: '皇帝（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'地位のある人に出会えたり、自分がワンランク上の立場になりそう。リーダーシップを発揮できるでしょう。',
                                '自分':'ワンランク上の立場になりそう。リーダーシップを発揮できるでしょう。',
                                'お金':'お金にまつわる相談は地位のあるに相談すると良い結果になるでしょう。',
                                '知識':'地位のある人との会話がキーポイントに。有益な情報をもらえるかもしれません。',
                                '家族':'父親や祖父からの強い支援がもらえそう。',
                                '恋愛':'安定した人との恋愛ができそう。',
                                '健康':'これまでのルーティンの生活や仕事が落ち着き、健康も安定しそうです。',
                                '対人':'地位のある人に出会えたり、評価されそうです。',
                                '相続':'父親や祖父からの強い支援がもらえそう。',
                                '海外':'海外にまつわることをすることで立場を築けそうです。',
                                '仕事':'ワンランク上の立場になりそう。リーダーシップを発揮できるでしょう。',
                                '友人':'ワンランク上の立場になりそう。リーダーシップを発揮できるでしょう。',
                                '奉仕':'ワンランク上の立場になりそう。リーダーシップを発揮できるでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'地位のある人からの反対に合ったり、自分が安定しない立場になりそうです。',
                                '自分':'地位のある人からの反対に合ったり、自分が安定ない立場になりそうです。',
                                'お金':'金運が安定しません。計画性を持った運用が大事です。',
                                '知識':'知識が身に付きにくいかもしれません。',
                                '家族':'父親や祖父から反対に合ったり、自分が安定ない立場になりそうです。',
                                '恋愛':'年上で安定したお付き合いができそう。もしくはそういった人との出会いの可能性が。',
                                '健康':'皇帝（逆位置）の「健康」結果が入ります',
                                '対人':'地位のある人からの反対に合ったり、自分が安定しない立場になりそうです。',
                                '相続':'地位のある人からの反対に合ったり、自分が安定しない立場になりそうです。',
                                '海外':'皇帝（逆位置）の「海外」結果が入ります',
                                '仕事':'地位のある人からの反対に合ったり、自分が安定しない立場になりそうです。',
                                '友人':'皇帝（逆位置）の「友人」結果が入ります',
                                '奉仕':'皇帝（逆位置）の「奉仕」結果が入ります'
                            },
                    },
                    {
                            no: 5,
                            imgUrl: require('@/assets/tarotcard_05.png'),
                            name: {
                                normal: '教皇（正位置）',
                                reserve: '教皇（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'良い教師に出会えそうです。もしくは自分が教える立場になるかもしれません。モラルやルールを守り、道徳心のある行動ができそうです。',
                                '自分':'良い教師に出会えそうです。もしくは自分が教える立場になるかもしれません。モラルやルールを守り、道徳心のある行動ができそうです。',
                                'お金':'教皇（正位置）の「お金」結果が入ります',
                                '知識':'良い教師に出会えそうです。もしくは自分が教える立場になるかもしれません。',
                                '家族':'父親か祖父の支援に恵まれそうです。',
                                '恋愛':'尊敬できるような人との出会いに恵まれそうです。お付き合いしている相手を尊敬できそう。',
                                '健康':'自分の中でルーティンを決めると心にゆとりができそうです。',
                                '対人':'良い教師に出会えそうです。もしくは自分が教える立場になるかもしれません。',
                                '相続':'教養ある人からの支援を受けられそうです。',
                                '海外':'教養ある外国人との出会いが期待できます。',
                                '仕事':'良い上司に出会えそうです。もしくは自分が教える立場になるかもしれません。',
                                '友人':'モラルや教養ある良い友人に出会えそうです。モラルある付き合いができます。',
                                '奉仕':'良い教師に出会えそうです。もしくは自分が教える立場になるかもしれません。'
                            },
                            ResultReserve: {
                                'テーマ':'自分の決めたルールに誰かを従わせたり、モラルのない環境になりそうです。',
                                '自分':'自分の決めたルールに誰かを従わせたり、モラルのない環境になりそうです。',
                                'お金':'モラルのないお金稼ぎを考えたりするかもしれません。',
                                '知識':'モラルのない言動をする可能性があるので注意しましょう。',
                                '家族':'自分の決めたルールに誰かを従わせたり、もしくは相手から押し付けられ、モラルのない環境になりそうです。',
                                '恋愛':'自分の決めたルールに相手を従わせたり、もしくは相手から押し付けられ、モラルのない状況になりそうです。',
                                '健康':'規則を守らないことで健康に害があるかもしれません。',
                                '対人':'自分の決めたルールに相手を従わせたり、もしくは相手から押し付けられ、モラルのない状況になりそうです。',
                                '相続':'自分の決めたルールに相手を従わせたり、もしくは相手から押し付けられ、モラルのない状況になりそうです。',
                                '海外':'海外旅行では良くない環境に足を踏み入れたり、モラルのない外国人に出会いそう。',
                                '仕事':'自分の決めたルールに相手を従わせたり、もしくは相手から押し付けられ、モラルのない状況になりそうです。',
                                '友人':'自分の決めたルールに相手を従わせたり、もしくは相手から押し付けられ、モラルのない状況になりそうです。',
                                '奉仕':'自分の決めたルールに相手を従わせたり、もしくは相手から押し付けられ、モラルのない状況になりそうです。'
                            },
                    },
                    {
                            no: 6,
                            imgUrl: require('@/assets/tarotcard_06.png'),
                            name: {
                                normal: '恋人（正位置）',
                                reserve: '恋人（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'運命的な出会いがありそう。自分のインスピレーションを信じましょう。何かが成就する予感がします。',
                                '自分':'運命的な出会いがありそう。自分のインスピレーションを信じましょう。何かが成就する予感がします。',
                                'お金':'直感を頼りにすると臨時収入が期待できそうです。ビビッとした感覚を大事にしましょう。',
                                '知識':'興味の持てる分野を発見できそう。お互いが楽しめるようなコミュニケーションも取れそうです。',
                                '家族':'家族の中で何かが成就する予感がします。直感を頼りにしましょう。',
                                '恋愛':'運命的な出会いがありそう。自分のインスピレーションを信じましょう。何かが成就する予感がします。',
                                '健康':'普段取り入れない新しい健康法に目覚めたり、日常生活の中で楽しいルーティンを見つけることができそうです。',
                                '対人':'運命的な出会いがありそう。自分のインスピレーションを信じましょう。何かが成就する予感がします。',
                                '相続':'深い関係の人との嬉しい契約ごとがありそうです。直感で良いと感じたらぜひ受け入れてみてください。',
                                '海外':'自分が行きたいと思った遠い場所とのご縁がありそうです。',
                                '仕事':'やりがいのある楽しい仕事に巡り会えそう。または和気藹々とした楽しい職場環境に恵まれます。',
                                '友人':'運命的な出会いがありそう。自分のインスピレーションを信じましょう。何かが成就する予感がします。',
                                '奉仕':'運命的な出会いがありそう。自分のインスピレーションを信じましょう。何かが成就する予感がします。'
                            },
                            ResultReserve: {
                                'テーマ':'契約ごとは結べない可能性が高いです。がっかりするかもしれませんが、ご縁がなかったのかもしれません。',
                                '自分':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                'お金':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                '知識':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                '家族':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                '恋愛':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                '健康':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                '対人':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                '相続':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                '海外':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                '仕事':'契約ごとは結べない可能性が高いです。がっかりするかもしれませんが、ご縁がなかったのかもしれません。',
                                '友人':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                '奉仕':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。'
                            },
                    },
                    {
                            no: 7,
                            imgUrl: require('@/assets/tarotcard_07.png'),
                            name: {
                                normal: '戦車（正位置）',
                                reserve: '戦車（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'何か迷っていたりやりたいことがあるのなら迷わず進みましょう、GOサインが出ています。とにかくやってみることが大事なのです。',
                                '自分':'自分を信じて突き進んでみて。全速前進でいきましょう。',
                                'お金':'自分を信じて突き進んでみて。全速前進でいきましょう。',
                                '知識':'自分を信じて突き進んでみて。全速前進でいきましょう。',
                                '家族':'家族を信じて突き進んでみて。全速前進でいきましょう。',
                                '恋愛':'告白に迷っているのならまずぶつかってみましょう。自分に素直に、信じることが大事なのです。',
                                '健康':'何か迷っていたりやりたいことがあるのなら迷わず進みましょう、GOサインが出ています。とにかくやってみることが大事なのです。',
                                '対人':'対人関係で何か迷っていたりやりたいことがあるのなら迷わず進みましょう、GOサインが出ています。とにかくやってみることが大事なのです。',
                                '相続':'何か迷っていたりやりたいことがあるのなら迷わず進みましょう、GOサインが出ています。とにかくやってみることが大事なのです。',
                                '海外':'何か迷っていたりやりたいことがあるのなら迷わず進みましょう、GOサインが出ています。とにかくやってみることが大事なのです。',
                                '仕事':'何か迷っていたりやりたいことがあるのなら迷わず進みましょう、GOサインが出ています。とにかくやってみることが大事なのです。',
                                '友人':'何か迷っていたりやりたいことがあるのなら迷わず進みましょう、GOサインが出ています。とにかくやってみることが大事なのです。',
                                '奉仕':'何か迷っていたりやりたいことがあるのなら迷わず進みましょう、GOサインが出ています。とにかくやってみることが大事なのです。'
                            },
                            ResultReserve: {
                                'テーマ':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '自分':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                'お金':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '知識':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '家族':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '恋愛':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '健康':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '対人':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '相続':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '海外':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '仕事':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '友人':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '奉仕':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。'
                            },
                    },
                    {
                            no: 8,
                            imgUrl: require('@/assets/tarotcard_08.png'),
                            name: {
                                normal: '力（正位置）',
                                reserve: '力（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'強い精神力を持てる時。あなたの忍耐力を信じて少しずつ進みましょう。そうすれば良い結果に結びつくはずです。',
                                '自分':'強い精神力を持てる時。あなたの忍耐力を信じて少しずつ進みましょう。そうすれば良い結果に結びつくはずです。',
                                'お金':'長期的な資産運用に向く時。今のあなたなら忍耐強くお金を稼いだり溜めたりできるでしょう。',
                                '知識':'難しい勉強でも前向きに忍耐強くじっくり取り組める時。またコミュニケーションでも相手を受け入れ上手にコントロールできるでしょう。',
                                '家族':'家族の中で何か中・長期的に取り組めるものを始めるのに向いています。',
                                '恋愛':'どんな試練があっても二人の愛は順調に育まれていくでしょう。長い付き合いのできるパートナーにも恵まれそう。',
                                '健康':'強い精神力を持てる時。あなたの忍耐力を信じて少しずつ進みましょう。そうすれば良い結果に結びつくはずです。',
                                '対人':'長い付き合いのできるパートナーにも恵まれそう。',
                                '相続':'強い精神力を持てる時。あなたの忍耐力を信じて少しずつ進みましょう。そうすれば良い結果に結びつくはずです。',
                                '海外':'強い精神力を持てる時。あなたの忍耐力を信じて少しずつ進みましょう。そうすれば良い結果に結びつくはずです。',
                                '仕事':'強い精神力を持てる時。あなたの忍耐力を信じて少しずつ進みましょう。そうすれば良い結果に結びつくはずです。',
                                '友人':'長い付き合いのできる友人に恵まれそう。',
                                '奉仕':'強い精神力を持てる時。あなたの忍耐力を信じて少しずつ進みましょう。そうすれば良い結果に結びつくはずです。'
                            },
                            ResultReserve: {
                                'テーマ':'我慢強くいられず、感情的になりうまくいかないようです。焦りは禁物です。長い目を見て取り組むことが大事になります。',
                                '自分':'我慢強くいられず、感情的になりうまくいかないようです。焦りは禁物です。長い目を見て取り組むことが大事になります。',
                                'お金':'我慢強くいられず、感情的になりうまくいかないようです。焦りは禁物です。長い目を見て取り組むことが大事になります。',
                                '知識':'我慢強くいられず、感情的になりうまくいかないようです。焦りは禁物です。長い目を見て取り組むことが大事になります。',
                                '家族':'我慢強くいられず、感情的になりうまくいかないようです。焦りは禁物です。長い目を見て取り組むことが大事になります。',
                                '恋愛':'我慢強くいられず、感情的になりうまくいかないようです。もしくはパートナーに振り回される可能性も',
                                '健康':'我慢強くいられず、感情的になりうまくいかないようです。焦りは禁物です。長い目を見て取り組むことが大事になります。',
                                '対人':'我慢強くいられず、感情的になりうまくいかないようです。もしくはパートナーに振り回される可能性も',
                                '相続':'我慢強くいられず、感情的になりうまくいかないようです。もしくは現在の状況に振り回される可能性も',
                                '海外':'我慢強くいられず、感情的になりうまくいかないようです。焦りは禁物です。長い目を見て取り組むことが大事になります。',
                                '仕事':'我慢強くいられず、感情的になりうまくいかないようです。もしくはパートナーに振り回される可能性も。',
                                '友人':'我慢強くいられず、感情的になりうまくいかないようです。もしくは友人に振り回される可能性も。',
                                '奉仕':'我慢強くいられず、感情的になりうまくいかないようです。もしくは環境に振り回される可能性も。'
                            },
                    },
                    {
                            no: 9,
                            imgUrl: require('@/assets/tarotcard_09.png'),
                            name: {
                                normal: '隠者（正位置）',
                                reserve: '隠者（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'静かな環境で自分の可能性を探しています。その考えは正しそうです。じっくりと自分に向き合うと良いでしょう。',
                                '自分':'静かな環境で自分の可能性を探しています。その考えは正しそうです。じっくりと自分に向き合うと良いでしょう。',
                                'お金':'静かな環境で可能性を探しています。その考えは正しそうです。じっくりと向き合うと良いでしょう。',
                                '知識':'その進め方や取り組みは正しそうです。じっくりと目の前の課題と向き合うと良いでしょう。',
                                '家族':'家族との関係性と向き合いじっくり考えているようです。その考えは正しそうなので、この機会にじっくりと向き合ってみてください。',
                                '恋愛':'相手との関係性についてじっくりと内省しているようです。その考えは正しそうなので、この機会にじっくりと向き合ってみてください。もしくは相手は一人でじっくりと考えたいタイミングだったり、熟考したいタイプなのかもしれません。',
                                '健康':'その進め方や取り組みは正しそうです。じっくりと目の前の課題と向き合うと良いでしょう。',
                                '対人':'相手との関係性についてじっくりと内省しているようです。もしくは相手が一人でじっくりと考えたいタイミングだったり、熟考したいタイプなのかもしれません。',
                                '相続':'もし考え事があるのならその方向性で良いようです。時間をかけても良いのでじっくりと考えましょう。',
                                '海外':'海外にまつわることで自分の可能性を模索しているかもしれません。その考えは正しそうです。じっくりと自分に向き合うと良いでしょう。',
                                '仕事':'自分をきちんと内省でいているようです。今後の方向性についてじっくりと考えて進めていきたいようなので、きちんと向き合うのがおすすめです。',
                                '友人':'相手との関係性についてじっくりと内省しているようです。その考えは正しそうなので、この機会にじっくりと向き合ってみてください。もしくは相手は一人でじっくりと考えたいタイミングだったり、熟考したいタイプなのかもしれません。',
                                '奉仕':'静かな環境で可能性を模索しています。その考えは正しそうです。じっくりと向き合うと良いでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたの視野が狭すぎるようです。思い込みで進めないように注意してください。もしくは孤独な環境になっているのかもしれません。',
                                '自分':'あなたの視野が狭すぎるようです。思い込みで進めないように注意してください。もしくは孤独な環境になっているのかもしれません。',
                                'お金':'自分の思い込みで失敗しそう。今は無駄遣いを控えましょう。',
                                '知識':'あなたの視野が狭すぎるようです。思い込みで進めないように注意してください。もしくは孤独な環境になっているのかもしれません。',
                                '家族':'家族の中で孤立した状況になりそうです。自分の思い込みではなく家族の意見も聞いてみてください。もしくはそういう孤独を感じている家族がいるのかもしれません。',
                                '恋愛':'あなたの視野が狭すぎるようです。思い込みで進めないように注意してください。もしくは孤独な環境になっているのかもしれません。',
                                '健康':'あなたの視野が狭すぎるようです。思い込みで進めないように注意してください。もしくは孤独な環境になっているのかもしれません。',
                                '対人':'あなたの視野が狭すぎるようです。思い込みで進めないように注意してください。もしくは孤独な環境になっているのかもしれません。',
                                '相続':'あなたの視野が狭すぎるようです。思い込みで進めないように注意してください。もしくは孤独な環境になっているのかもしれません。',
                                '海外':'あなたの視野が狭すぎるようです。思い込みで進めないように注意してください。もしくは孤独な環境になっているのかもしれません。',
                                '仕事':'仕事場で孤立した状況になりそうです。自分の思い込みで進めるのではなく、他の人の意見も聞いてみてください。',
                                '友人':'友人関係で孤立した状況になりそうです。自分の思い込みで進めるのではなく、相手の意見も聞いてみてください。',
                                '奉仕':'孤立した環境になってしまいそうです。思い込みで進めないように注意してください。'
                            },
                    },
                    {
                            no: 10,
                            imgUrl: require('@/assets/tarotcard_10.png'),
                            name: {
                                normal: '運命の輪（正位置）',
                                reserve: '運命の輪（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                                '自分':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                                'お金':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                                '知識':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                                '家族':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                                '恋愛':'あなたの人生に関わる大きな幸運のチャンスが訪れています。良いパートナーに恵まれそうです。',
                                '健康':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                                '対人':'あなたの人生に関わる大きな幸運のチャンスが訪れています。良いパートナーに恵まれそうです。',
                                '相続':'深い関係の人や、遺産相続など何かを譲り受けるチャンスが訪れています。',
                                '海外':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                                '仕事':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                                '友人':'あなたの人生に関わる大きな幸運のチャンスが訪れています。友人との共同作業や、良い友人との出会いが期待できます。',
                                '奉仕':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。'
                            },
                            ResultReserve: {
                                'テーマ':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '自分':'何かが空回りすやすい状況です。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                'お金':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '知識':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '家族':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '恋愛':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '健康':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '対人':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '相続':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '海外':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '仕事':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '友人':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '奉仕':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。'
                            },
                    },
                    {
                            no: 11,
                            imgUrl: require('@/assets/tarotcard_11.png'),
                            name: {
                                normal: '正義（正位置）',
                                reserve: '正義（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                                '自分':'冷静で正しい判断ができる日。',
                                'お金':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                                '知識':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                                '家族':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                                '恋愛':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                                '健康':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                                '対人':'相手から過去に行った結果に対する判断が下される状況になりそう。',
                                '相続':'相手から過去に行った結果に対する判断が下される状況になりそう。',
                                '海外':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                                '仕事':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                                '友人':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                                '奉仕':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。'
                            },
                            ResultReserve: {
                                'テーマ':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                                '自分':'冷静さがなく正しい判断ができない日。',
                                'お金':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                                '知識':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                                '家族':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                                '恋愛':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                                '健康':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                                '対人':'相手から過去に行った結果に対する厳しい判断が下される、もしくは判断する状況になりそう。',
                                '相続':'相手から過去に行った結果に対する厳しい判断が下される、もしくは判断する状況になりそう。',
                                '海外':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                                '仕事':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                                '友人':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                                '奉仕':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。'
                            },
                    },
                    {
                            no: 12,
                            imgUrl: require('@/assets/tarotcard_12.png'),
                            name: {
                                normal: '吊られた男（正位置）',
                                reserve: '吊られた男（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。誰かに献身的に奉仕すると良いでしょう。',
                                '自分':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。誰かに献身的に奉仕すると良いでしょう。',
                                'お金':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。誰かに献身的に奉仕すると良いでしょう。',
                                '知識':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。誰かに献身的に奉仕すると良いでしょう。',
                                '家族':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。相手に献身的に奉仕すると良いでしょう。',
                                '恋愛':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。相手に献身的に奉仕すると良いでしょう。',
                                '健康':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。',
                                '対人':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。相手に献身的に奉仕すると良いでしょう。',
                                '相続':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。相手に献身的に奉仕すると良いでしょう。',
                                '海外':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。相手に献身的に奉仕すると良いでしょう。',
                                '仕事':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。相手に献身的に奉仕すると良いでしょう。',
                                '友人':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。相手に献身的に奉仕すると良いでしょう。',
                                '奉仕':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。相手に献身的に奉仕すると良いでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '自分':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                'お金':'吊られた男（逆位置）の「お金」結果が入ります',
                                '知識':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '家族':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '恋愛':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '健康':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '対人':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '相続':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '海外':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '仕事':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '友人':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '奉仕':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。'
                            },
                    },
                    {
                            no: 13,
                            imgUrl: require('@/assets/tarotcard_13.png'),
                            name: {
                                normal: '死神（正位置）',
                                reserve: '死神（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。ダラダラと続けるより白黒はっきりつけた方が良さそうです。',
                                '自分':'あなたの転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                'お金':'あなたの転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '知識':'あなたの転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '家族':'転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '恋愛':'転換期が来ています。パートナーとの関係は終了するでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '健康':'転換期が来ています。これまで続けてきたルーティンは終了するでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '対人':'転換期が来ています。パートナーとの関係は終了するでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '相続':'あなたの転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '海外':'あなたの転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '仕事':'あなたの転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '友人':'友人とのお別れがあるかもしれません。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '奉仕':'それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。'
                            },
                            ResultReserve: {
                                'テーマ':'何かが終了し、新しい展開が始まる予感がします。もし、ダラダラと続けていることがあれば迷わず蹴りをつけてください。終わらせるからこそ新しい世界が開けるのです。変化を恐れないでください。',
                                '自分':'これまでの自分がリセットされ、新しい自分に出会えるかもしれません。白黒はっきりした方が良い結果になるでしょう。',
                                'お金':'これまでの金銭感覚がリセットされ、新しいお金稼ぎの方法に出会えるかもしれません。これまでとは違うやり方の方が良い結果になるでしょう。',
                                '知識':'これまでの知識やコミュニケーション方法がリセットされ、新しい方法に出会えるかもしれません。これまでとは違うやり方の方が良い結果になるでしょう。',
                                '家族':'何かが終了し、新しい展開が始まる予感がします。もし、ダラダラと続けていることがあれば迷わず蹴りをつけてください。終わらせるからこそ新しい世界が開けるのです。',
                                '恋愛':'これまでの関係は終了し、新しい展開が始まる予感がします。もし、ダラダラと続けている関係性であれば迷わず蹴りをつけてください。終わらせるからこそ新しい世界が開けるのです。',
                                '健康':'何かが終了し、新しい展開が始まる予感がします。もし、ダラダラと続けていることがあれば迷わず蹴りをつけてください。終わらせるからこそ新しい世界が開けるのです。変化を恐れないでください。',
                                '対人':'もし、ダラダラと続けている関係性があれば迷わず蹴りをつけてください。終わらせるからこそ新しい世界が開けるのです。',
                                '相続':'親しい人や深い関係、遺産や相続などでダラダラと続けている関係性があれば迷わず蹴りをつけてください。終わらせるからこそ新しい世界が開けるのです。',
                                '海外':'何かが終了し、新しい展開が始まる予感がします。もし、ダラダラと続けていることがあれば迷わず蹴りをつけてください。終わらせるからこそ新しい世界が開けるのです。変化を恐れないでください。',
                                '仕事':'これまでの仕事は一旦蹴りをつけ、新しい仕事が生まれる予感がします。ダラダラと続けるより、いっそ潔く辞める方が新しい環境へスムーズに行けるでしょう。',
                                '友人':'もし、ダラダラと続けている関係性があれば迷わず蹴りをつけてください。終わらせるからこそ新しい世界が開けるのです。',
                                '奉仕':'もし、ダラダラと続けている関係性があれば迷わず蹴りをつけてください。終わらせるからこそ新しい世界が開けるのです。'
                            },
                    },
                    {
                            no: 14,
                            imgUrl: require('@/assets/tarotcard_14.png'),
                            name: {
                                normal: '節制（正位置）',
                                reserve: '節制（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'秩序が保たれています。ちょうど良いバランス感覚で、何かと何かを繋げる役割を持つかもしれません。白黒つけず、グレーで絶妙な状態がキープできるでしょう。高望みをせず無理のない行動が吉となります。',
                                '自分':'白黒つけず、グレーで絶妙な状態がキープできるでしょう。高望みをせず無理のない行動が吉となります。',
                                'お金':'白黒つけず、グレーで絶妙な状態がキープできるでしょう。高望みをせず無理のない行動が吉となります。',
                                '知識':'白黒つけず、グレーで絶妙な状態がキープできるでしょう。高望みをせず無理のない行動が吉となります。',
                                '家族':'秩序が保たれています。ちょうど良いバランス感覚で、何かと何かを繋げる役割を持つかもしれません。白黒つけず、グレーで絶妙な状態がキープできるでしょう。高望みをせず無理のない行動が吉となります。',
                                '恋愛':'絶妙なバランス感覚で相手と良い関係性をキープできそうです。相手の気持ちを汲み取れるような穏やかなパートナーとも巡り会えそうです。',
                                '健康':'穏やかな状態が保たれています。無理のない行動が吉となります。',
                                '対人':'絶妙なバランス感覚で相手と良い関係性をキープできそうです。相手の気持ちを汲み取れるような穏やかなパートナーとも巡り会えそうです。',
                                '相続':'絶妙なバランス感覚で相手と良い関係性をキープできそうです。相手の気持ちを汲み取れるような穏やかなパートナーとも巡り会えそうです。',
                                '海外':'絶妙なバランス感覚で相手と良い関係性をキープできそうです。相手の気持ちを汲み取れるような穏やかなパートナーとも巡り会えそうです。',
                                '仕事':'絶妙なバランス感覚で良い仕事ぶりをキープできそうです。何かと何かを繋げる役割を持つかもしれません。相手の気持ちを汲み取ることが肝になりそうです。',
                                '友人':'絶妙なバランス感覚で相手と良い関係性をキープできそうです。相手の気持ちを汲み取れるような穏やかなパートナーとも巡り会えそうです。',
                                '奉仕':'秩序が保たれています。ちょうど良いバランス感覚で、何かと何かを繋げる役割を持つかもしれません。白黒つけず、グレーで絶妙な状態がキープできるでしょう。高望みをせず無理のない行動が吉となります。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたのどちらつかずな対応が仇となるかもしれません。もしくは周りの環境に自分のバランスが保てなくなる可能性もあります。自分のペースが乱される環境は避けましょう。',
                                '自分':'あなたのどちらつかずな対応が仇となるかもしれません。また自分のペースが乱される環境は避けましょう。',
                                'お金':'あなたのどちらつかずな対応が仇となるかもしれません。また自分のペースが乱される環境は避けましょう。',
                                '知識':'あなたのどちらつかずな対応が仇となるかもしれません。もしくは周りの環境に自分のバランスが保てなくなる可能性もあります。自分のペースが乱される環境は避けましょう。',
                                '家族':'あなたのどちらつかずな対応が仇となるかもしれません。もしくは家族の状況に乱され自分のバランスが保てなくなる可能性もあります。お互いのペースが乱される環境は避けましょう。',
                                '恋愛':'節度を超えた付き合いになる可能性があります。グレーな対応が仇となることも。お互いのペースが乱される環境は避けましょう。',
                                '健康':'健康運はあまり良くないでしょう。節度を保った行動を心がけてください。',
                                '対人':'節度を超えた付き合いになる可能性があります。グレーな対応が仇となることも。お互いのペースが乱される環境は避けましょう。',
                                '相続':'節度を超えた付き合いになる可能性があります。グレーな対応が仇となることも。もし相手が深刻なようであればあまり突っ込まない方が良いかもしれません。',
                                '海外':'節度を超えた行動をとりがちなので、慎重な行動を心がけましょう。',
                                '仕事':'あなたのどちらつかずな対応が仇となるかもしれません。もしくは周りの環境に自分のバランスが保てなくなる可能性もあります。自分のペースが乱される環境は避けましょう。',
                                '友人':'あなたのどちらつかずな対応が仇となるかもしれません。もしくは周りの環境に自分のバランスが保てなくなる可能性もあります。自分のペースが乱される環境は避けましょう。',
                                '奉仕':'あなたのどちらつかずな対応が仇となるかもしれません。もしくは周りの環境に自分のバランスが保てなくなる可能性もあります。自分のペースが乱される環境は避けましょう。'
                            },
                    },
                    {
                            no: 15,
                            imgUrl: require('@/assets/tarotcard_15.png'),
                            name: {
                                normal: '悪魔（正位置）',
                                reserve: '悪魔（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'何かに執着しているかもしれません。これまでの柵に囚われています。そのまま続けるのは良くないとわかっていても辞められない状況に陥りがちなので、抜けられる強い意思を持つことが大事です。',
                                '自分':'何かに執着しているかもしれません。これまでの柵に囚われています。そのまま続けるのは良くないとわかっていても辞められない状況に陥りがちなので、抜けられる強い意思を持つことが大事です。',
                                'お金':'お金にまつわることで強い執着があるようです。ぬるま湯に浸かっている状態であれば、このまま続けるのは良くないでしょう。別の方法も考えるのも一つの手です。',
                                '知識':'ある一つのものに強い執着があるようです。ただ良い状態ではないので、このまま続けるのは良くないでしょう。別の方法も考えるのも一つの手です。',
                                '家族':'家族に対して強い執着があるようです。ただ良い状態ではないので、このまま続けるのは良くないでしょう。別の方法も考えるのも一つの手です。',
                                '恋愛':'恋愛関係の相手に対して強い執着があるようです。ただ良い状態ではないので、このまま続けるのは良くないでしょう。ぬるま湯に浸かっている状態から抜けられる強い意思を持つことが大事です。',
                                '健康':'惰性的で良くない生活習慣を送っているかもしれません。あまり良い状態とは言えないので、このまま続けるのは良くないでしょう。別の方法も考えるのも一つの手です。',
                                '対人':'相手から強い執着を受けるか、相手に対して強い執着があるようです。ただ良い状態ではないので、このまま続けるのは良くないでしょう。ぬるま湯に浸かっている状態から抜けられる強い意思を持つことが大事です。',
                                '相続':'親しい関係性の相手や物事から強い執着を受けるか、相手に対して強い執着があるようです。ただ良い状態ではないので、このまま続けるのは良くないでしょう。ぬるま湯に浸かっている状態から抜けられる強い意思を持つことが大事です。',
                                '海外':'海外にまつわる何かに強い執着心があるようです。そのまま続けるのは良くないとわかっていても辞められない状況に陥りがちなので、抜けられる強い意思を持つことが大事です。',
                                '仕事':'辞めたいのに辞められない仕事やブラックな環境、もしくは仕事関係の人から強い執着を受けることがあるかもしれません。そのまま続けるのは良くないとわかっていても辞められない状況に陥りがちなので、抜けられる強い意思を持つことが大事です。',
                                '友人':'相手から強い執着を受けるか、相手に対して強い執着があるようです。ただ良い状態ではないので、このまま続けるのは良くないでしょう。ぬるま湯に浸かっている状態から抜けられる強い意思を持つことが大事です。',
                                '奉仕':'悪魔（正位置）の「奉仕」結果が入ります'
                            },
                            ResultReserve: {
                                'テーマ':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '自分':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                'お金':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '知識':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '家族':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '恋愛':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '健康':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '対人':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '相続':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '海外':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '仕事':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '友人':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '奉仕':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。'
                            },
                    },
                    {
                            no: 16,
                            imgUrl: require('@/assets/tarotcard_16.png'),
                            name: {
                                normal: '塔（正位置）',
                                reserve: '塔（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。ただしそれはあなたの間違った考えや環境から抜け出せるチャンスでもあるのです。',
                                '自分':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。ただしそれはあなたの間違った考えや環境から抜け出せるチャンスでもあるのです。',
                                'お金':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。ただしそれはあなたの間違った考えや環境から抜け出せるチャンスでもあるのです。',
                                '知識':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので特に発言には注意してください。',
                                '家族':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。',
                                '恋愛':'あなたにとって衝撃的な出会い、つまり一目惚れがあるかもしれません。もしくは相手との不意なトラブルが起きる可能性があるので注意してください。',
                                '健康':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。ただしそれはあなたの間違った考えや環境から抜け出せるチャンスでもあるのです。',
                                '対人':'衝撃的な出会いがあるかもしれません。もしくは人間関係のトラブルに巻き込まれる可能性があるので注意してください。',
                                '相続':'相続や不労所得などでトラブルに巻き込まれる可能性があるので注意してください。',
                                '海外':'海外旅行や遠方への旅行中でトラブルに巻き込まれる可能性があるので注意してください。',
                                '仕事':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。',
                                '友人':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。',
                                '奉仕':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。ただしそれはあなたの間違った考えや環境から抜け出せるチャンスでもあるのです。'
                            },
                            ResultReserve: {
                                'テーマ':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '自分':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                'お金':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '知識':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '家族':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '恋愛':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '健康':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '対人':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '相続':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '海外':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '仕事':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '友人':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '奉仕':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。'
                            },
                    },
                    {
                            no: 17,
                            imgUrl: require('@/assets/tarotcard_17.png'),
                            name: {
                                normal: '星（正位置）',
                                reserve: '星（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。',
                                '自分':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。',
                                'お金':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。',
                                '知識':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。',
                                '家族':'あなたの家族は夢と希望に満ち溢れています。',
                                '恋愛':'理想的な相手との出会いが期待できます。あなたは夢と希望に満ち溢れています。',
                                '健康':'理想的な健康法を思いついたり、普段の生活の良いイメージが湧いています。あなたの掲げる目標に向かって歩むことで理想が実現できるでしょう。',
                                '対人':'理想的な相手との出会いが期待できます。あなたは夢と希望に満ち溢れています。',
                                '相続':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。',
                                '海外':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。',
                                '仕事':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。',
                                '友人':'理想的な相手との出会いが期待できます。あなたは夢と希望に満ち溢れています。',
                                '奉仕':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。'
                            },
                            ResultReserve: {
                                'テーマ':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                '自分':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                'お金':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                '知識':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                '家族':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                '恋愛':'相手に対する理想が高すぎて幻滅するかもしれません。理想を下げるのも一つの手かもしれません。',
                                '健康':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                '対人':'相手に対する理想が高すぎて幻滅するかもしれません。理想を下げるのも一つの手かもしれません。',
                                '相続':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                '海外':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                '仕事':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                '友人':'相手に対する理想が高すぎて幻滅するかもしれません。理想を下げるのも一つの手かもしれません。',
                                '奉仕':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。'
                            },
                    },
                    {
                            no: 18,
                            imgUrl: require('@/assets/tarotcard_18.png'),
                            name: {
                                normal: '月（正位置）',
                                reserve: '月（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '自分':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                'お金':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '知識':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '家族':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '恋愛':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '健康':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '対人':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '相続':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '海外':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '仕事':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '友人':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '奉仕':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。'
                            },
                            ResultReserve: {
                                'テーマ':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '自分':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                'お金':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '知識':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '家族':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '恋愛':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '健康':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '対人':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '相続':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '海外':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '仕事':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '友人':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '奉仕':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。'
                            },
                    },
                    {
                            no: 19,
                            imgUrl: require('@/assets/tarotcard_19.png'),
                            name: {
                                normal: '太陽（正位置）',
                                reserve: '太陽（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '自分':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                'お金':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '知識':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '家族':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '恋愛':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '健康':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '対人':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '相続':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '海外':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '仕事':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '友人':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '奉仕':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。'
                            },
                            ResultReserve: {
                                'テーマ':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '自分':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                'お金':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '知識':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '家族':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '恋愛':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '健康':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '対人':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '相続':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '海外':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '仕事':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '友人':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '奉仕':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。'
                            },
                    },
                    {
                            no: 20,
                            imgUrl: require('@/assets/tarotcard_20.png'),
                            name: {
                                normal: '審判（正位置）',
                                reserve: '審判（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れたり、復縁の可能性があります。',
                                '自分':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。',
                                'お金':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。',
                                '知識':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。',
                                '家族':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。',
                                '恋愛':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れたり、復縁の可能性があります。',
                                '健康':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。',
                                '対人':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れたり、復縁の可能性があります。',
                                '相続':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。',
                                '海外':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。',
                                '仕事':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。',
                                '友人':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れたり、復縁の可能性があります。',
                                '奉仕':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '自分':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                'お金':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '知識':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '家族':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '恋愛':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '健康':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '対人':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '相続':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '海外':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '仕事':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '友人':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '奉仕':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。'
                            },
                    },
                    {
                            no: 21,
                            imgUrl: require('@/assets/tarotcard_21.png'),
                            name: {
                                normal: '世界（正位置）',
                                reserve: '世界（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '自分':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                'お金':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '知識':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '家族':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '恋愛':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '健康':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '対人':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '相続':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '海外':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '仕事':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '友人':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '奉仕':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                '自分':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                'お金':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                '知識':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                '家族':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                '恋愛':'相手は中途半端な状態かもしれません。二人の関係はまだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。',
                                '健康':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                '対人':'相手は中途半端な状態かもしれません。二人の関係はまだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。',
                                '相続':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                '海外':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                '仕事':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                '友人':'相手は中途半端な状態かもしれません。二人の関係はまだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは協調性を意識してみてください。',
                                '奉仕':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。'
                            },
                    },
                    {
                            no: 101,
                            imgUrl: require('@/assets/tarotcard_w1.png'),
                            name: {
                                normal: 'ワンドエース',
                                reserve: 'ワンドエース（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '自分':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                'お金':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '知識':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '家族':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '恋愛':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '健康':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '対人':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '相続':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '海外':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '仕事':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '友人':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '奉仕':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '自分':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                'お金':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '知識':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '家族':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '恋愛':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '健康':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '対人':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '相続':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '海外':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '仕事':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '友人':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '奉仕':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。'
                            },
                    },
                    {
                            no: 102,
                            imgUrl: require('@/assets/tarotcard_w2.png'),
                            name: {
                                normal: 'ワンド2',
                                reserve: 'ワンド2（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '自分':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                'お金':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '知識':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '家族':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '恋愛':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '健康':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '対人':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '相続':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '海外':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '仕事':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '友人':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '奉仕':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '自分':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                'お金':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '知識':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '家族':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '恋愛':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '健康':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '対人':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '相続':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '海外':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '仕事':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '友人':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '奉仕':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。'
                            },
                    },
                    {
                            no: 103,
                            imgUrl: require('@/assets/tarotcard_w3.png'),
                            name: {
                                normal: 'ワンド3',
                                reserve: 'ワンド3（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '自分':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                'お金':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '知識':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '家族':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '恋愛':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '健康':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '対人':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '相続':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '海外':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '仕事':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '友人':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '奉仕':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '自分':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                'お金':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '知識':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '家族':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '恋愛':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '健康':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '対人':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '相続':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '海外':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '仕事':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '友人':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '奉仕':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。'
                            },
                    },
                    {
                            no: 104,
                            imgUrl: require('@/assets/tarotcard_w4.png'),
                            name: {
                                normal: 'ワンド4',
                                reserve: 'ワンド4（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '自分':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                'お金':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '知識':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '家族':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '恋愛':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '健康':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '対人':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '相続':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '海外':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '仕事':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '友人':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '奉仕':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。'
                            },
                            ResultReserve: {
                                'テーマ':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '自分':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                'お金':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '知識':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '家族':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '恋愛':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '健康':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '対人':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '相続':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '海外':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '仕事':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '友人':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '奉仕':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。'
                            },
                    },
                    {
                            no: 105,
                            imgUrl: require('@/assets/tarotcard_w5.png'),
                            name: {
                                normal: 'ワンド5',
                                reserve: 'ワンド5（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '自分':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                'お金':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '知識':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '家族':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '恋愛':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '健康':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '対人':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '相続':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '海外':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '仕事':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '友人':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '奉仕':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。'
                            },
                            ResultReserve: {
                                'テーマ':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '自分':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                'お金':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '知識':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '家族':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '恋愛':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '健康':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '対人':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '相続':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '海外':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '仕事':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '友人':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '奉仕':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。'
                            },
                    },
                    {
                            no: 106,
                            imgUrl: require('@/assets/tarotcard_w6.png'),
                            name: {
                                normal: 'ワンド6',
                                reserve: 'ワンド6（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '自分':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                'お金':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '知識':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '家族':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '恋愛':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '健康':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '対人':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '相続':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '海外':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '仕事':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '友人':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '奉仕':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '自分':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                'お金':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '知識':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '家族':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '恋愛':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '健康':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '対人':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '相続':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '海外':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '仕事':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '友人':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '奉仕':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。'
                            },
                    },
                    {
                            no: 107,
                            imgUrl: require('@/assets/tarotcard_w7.png'),
                            name: {
                                normal: 'ワンド7',
                                reserve: 'ワンド7（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '自分':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                'お金':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '知識':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '家族':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '恋愛':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '健康':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '対人':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '相続':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '海外':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '仕事':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '友人':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '奉仕':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。'
                            },
                            ResultReserve: {
                                'テーマ':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '自分':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                'お金':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '知識':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '家族':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '恋愛':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '健康':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '対人':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '相続':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '海外':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '仕事':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '友人':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '奉仕':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。'
                            },
                    },
                    {
                            no: 108,
                            imgUrl: require('@/assets/tarotcard_w8.png'),
                            name: {
                                normal: 'ワンド8',
                                reserve: 'ワンド8（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '自分':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                'お金':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '知識':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '家族':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '恋愛':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '健康':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '対人':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '相続':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '海外':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '仕事':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '友人':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '奉仕':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。'
                            },
                            ResultReserve: {
                                'テーマ':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '自分':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                'お金':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '知識':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '家族':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '恋愛':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '健康':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '対人':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '相続':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '海外':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '仕事':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '友人':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '奉仕':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。'
                            },
                    },
                    {
                            no: 109,
                            imgUrl: require('@/assets/tarotcard_w9.png'),
                            name: {
                                normal: 'ワンド9',
                                reserve: 'ワンド9（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '自分':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                'お金':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '知識':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '家族':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '恋愛':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '健康':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '対人':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '相続':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '海外':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '仕事':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '友人':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '奉仕':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。'
                            },
                            ResultReserve: {
                                'テーマ':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '自分':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                'お金':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '知識':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '家族':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '恋愛':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '健康':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '対人':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '相続':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。。',
                                '海外':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '仕事':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '友人':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '奉仕':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。'
                            },
                    },
                    {
                            no: 110,
                            imgUrl: require('@/assets/tarotcard_w10.png'),
                            name: {
                                normal: 'ワンド10',
                                reserve: 'ワンド10（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '自分':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                'お金':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '知識':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '家族':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '恋愛':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '健康':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '対人':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '相続':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '海外':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '仕事':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '友人':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '奉仕':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '自分':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                'お金':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '知識':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '家族':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '恋愛':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '健康':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '対人':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '相続':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '海外':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '仕事':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '友人':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '奉仕':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。'
                            },
                    },
                    {
                            no: 111,
                            imgUrl: require('@/assets/tarotcard_w11.png'),
                            name: {
                                normal: 'ワンドペイジ',
                                reserve: 'ワンドペイジ（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '自分':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                'お金':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '知識':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '家族':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '恋愛':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '健康':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '対人':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '相続':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '海外':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '仕事':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '友人':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '奉仕':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。'
                            },
                            ResultReserve: {
                                'テーマ':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '自分':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                'お金':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '知識':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '家族':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '恋愛':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '健康':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '対人':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '相続':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '海外':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '仕事':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '友人':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '奉仕':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。'
                            },
                    },
                    {
                            no: 112,
                            imgUrl: require('@/assets/tarotcard_w12.png'),
                            name: {
                                normal: 'ワンドナイト',
                                reserve: 'ワンドナイト（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '自分':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                'お金':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '知識':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '家族':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '恋愛':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '健康':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '対人':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '相続':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '海外':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '仕事':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '友人':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '奉仕':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。'
                            },
                            ResultReserve: {
                                'テーマ':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '自分':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                'お金':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '知識':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '家族':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '恋愛':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '健康':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '対人':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '相続':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '海外':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '仕事':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '友人':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '奉仕':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。'
                            },
                    },
                    {
                            no: 113,
                            imgUrl: require('@/assets/tarotcard_w13.png'),
                            name: {
                                normal: 'ワンドクイーン',
                                reserve: 'ワンドクイーン（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '自分':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                'お金':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '知識':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '家族':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '恋愛':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '健康':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '対人':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '相続':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '海外':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '仕事':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '友人':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '奉仕':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。'
                            },
                            ResultReserve: {
                                'テーマ':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '自分':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                'お金':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '知識':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '家族':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '恋愛':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '健康':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '対人':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '相続':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '海外':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '仕事':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '友人':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '奉仕':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。'
                            },
                    },
                    {
                            no: 114,
                            imgUrl: require('@/assets/tarotcard_w14.png'),
                            name: {
                                normal: 'ワンドキング',
                                reserve: 'ワンドキング（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '自分':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                'お金':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '知識':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '家族':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '恋愛':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '健康':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '対人':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '相続':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '海外':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。。',
                                '仕事':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '友人':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '奉仕':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '自分':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                'お金':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '知識':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '家族':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '恋愛':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '健康':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '対人':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '相続':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '海外':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '仕事':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '友人':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '奉仕':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。'
                            },
                    },
                    {
                            no: 201,
                            imgUrl: require('@/assets/tarotcard_p1.png'),
                            name: {
                                normal: 'ペンタクルエース',
                                reserve: 'ペンタクルエース（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '自分':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                'お金':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '知識':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '家族':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '恋愛':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '健康':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '対人':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '相続':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '海外':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '仕事':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '友人':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '奉仕':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。'
                            },
                            ResultReserve: {
                                'テーマ':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '自分':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                'お金':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '知識':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '家族':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '恋愛':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '健康':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '対人':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '相続':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '海外':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '仕事':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '友人':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '奉仕':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。'
                            },
                    },
                    {
                            no: 202,
                            imgUrl: require('@/assets/tarotcard_p2.png'),
                            name: {
                                normal: 'ペンタクル2',
                                reserve: 'ペンタクル2（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '自分':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                'お金':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '知識':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '家族':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '恋愛':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '健康':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '対人':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '相続':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '海外':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '仕事':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '友人':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '奉仕':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。'
                            },
                            ResultReserve: {
                                'テーマ':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '自分':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                'お金':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '知識':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '家族':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '恋愛':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '健康':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '対人':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '相続':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '海外':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '仕事':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '友人':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '奉仕':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。'
                            },
                    },
                    {
                            no: 203,
                            imgUrl: require('@/assets/tarotcard_p3.png'),
                            name: {
                                normal: 'ペンタクル3',
                                reserve: 'ペンタクル3（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '自分':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                'お金':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '知識':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '家族':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '恋愛':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '健康':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '対人':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '相続':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '海外':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '仕事':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '友人':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '奉仕':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '自分':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                'お金':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '知識':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '家族':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '恋愛':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '健康':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '対人':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '相続':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '海外':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '仕事':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '友人':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '奉仕':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。'
                            },
                    },
                    {
                            no: 204,
                            imgUrl: require('@/assets/tarotcard_p4.png'),
                            name: {
                                normal: 'ペンタクル4',
                                reserve: 'ペンタクル4（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '自分':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                'お金':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '知識':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '家族':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '恋愛':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '健康':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '対人':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '相続':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '海外':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '仕事':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '友人':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '奉仕':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。'
                            },
                            ResultReserve: {
                                'テーマ':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '自分':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                'お金':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '知識':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '家族':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '恋愛':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '健康':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '対人':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '相続':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '海外':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '仕事':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '友人':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '奉仕':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。'
                            },
                    },
                    {
                            no: 205,
                            imgUrl: require('@/assets/tarotcard_p5.png'),
                            name: {
                                normal: 'ペンタクル5',
                                reserve: 'ペンタクル5（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '自分':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                'お金':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '知識':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '家族':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '恋愛':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '健康':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '対人':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '相続':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '海外':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '仕事':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '友人':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '奉仕':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。'
                            },
                            ResultReserve: {
                                'テーマ':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '自分':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                'お金':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '知識':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '家族':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '恋愛':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '健康':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '対人':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '相続':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '海外':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '仕事':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '友人':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '奉仕':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。'
                            },
                    },
                    {
                            no: 206,
                            imgUrl: require('@/assets/tarotcard_p6.png'),
                            name: {
                                normal: 'ペンタクル6',
                                reserve: 'ペンタクル6（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '自分':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                'お金':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '知識':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '家族':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '恋愛':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '健康':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '対人':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '相続':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '海外':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '仕事':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '友人':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '奉仕':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '自分':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                'お金':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '知識':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '家族':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '恋愛':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '健康':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '対人':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '相続':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '海外':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '仕事':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '友人':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '奉仕':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。'
                            },
                    },
                    {
                            no: 207,
                            imgUrl: require('@/assets/tarotcard_p7.png'),
                            name: {
                                normal: 'ペンタクル7',
                                reserve: 'ペンタクル7（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '自分':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                'お金':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '知識':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '家族':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '恋愛':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '健康':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '対人':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '相続':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '海外':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '仕事':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '友人':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '奉仕':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '自分':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                'お金':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '知識':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '家族':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '恋愛':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '健康':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '対人':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '相続':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '海外':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '仕事':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '友人':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '奉仕':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。'
                            },
                    },
                    {
                            no: 208,
                            imgUrl: require('@/assets/tarotcard_p8.png'),
                            name: {
                                normal: 'ペンタクル8',
                                reserve: 'ペンタクル8（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '自分':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                'お金':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '知識':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '家族':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '恋愛':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '健康':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '対人':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '相続':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '海外':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '仕事':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '友人':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '奉仕':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '自分':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                'お金':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '知識':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '家族':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '恋愛':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '健康':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '対人':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '相続':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '海外':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '仕事':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '友人':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '奉仕':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。'
                            },
                    },
                    {
                            no: 209,
                            imgUrl: require('@/assets/tarotcard_p9.png'),
                            name: {
                                normal: 'ペンタクル9',
                                reserve: 'ペンタクル9（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '自分':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                'お金':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '知識':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '家族':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '恋愛':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '健康':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '対人':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '相続':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '海外':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '仕事':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '友人':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '奉仕':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。'
                            },
                            ResultReserve: {
                                'テーマ':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '自分':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                'お金':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '知識':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '家族':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '恋愛':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '健康':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '対人':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '相続':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '海外':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '仕事':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '友人':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '奉仕':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。'
                            },
                    },
                    {
                            no: 210,
                            imgUrl: require('@/assets/tarotcard_p10.png'),
                            name: {
                                normal: 'ペンタクル10',
                                reserve: 'ペンタクル10（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '自分':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                'お金':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '知識':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '家族':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '恋愛':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '健康':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '対人':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '相続':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '海外':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '仕事':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '友人':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '奉仕':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '自分':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                'お金':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '知識':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '家族':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '恋愛':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '健康':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '対人':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '相続':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '海外':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '仕事':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '友人':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '奉仕':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。'
                            },
                    },
                    {
                            no: 211,
                            imgUrl: require('@/assets/tarotcard_p11.png'),
                            name: {
                                normal: 'ペンタクルペイジ',
                                reserve: 'ペンタクルペイジ（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '自分':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                'お金':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '知識':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '家族':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '恋愛':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '健康':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '対人':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '相続':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '海外':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '仕事':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '友人':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '奉仕':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '自分':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                'お金':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '知識':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '家族':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '恋愛':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '健康':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '対人':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '相続':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '海外':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '仕事':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '友人':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '奉仕':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。'
                            },
                    },
                    {
                            no: 212,
                            imgUrl: require('@/assets/tarotcard_p12.png'),
                            name: {
                                normal: 'ペンタクルナイト',
                                reserve: 'ペンタクルナイト（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '自分':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                'お金':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '知識':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '家族':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '恋愛':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '健康':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '対人':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '相続':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '海外':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '仕事':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '友人':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '奉仕':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。'
                            },
                            ResultReserve: {
                                'テーマ':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '自分':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                'お金':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '知識':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '家族':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '恋愛':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '健康':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '対人':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '相続':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '海外':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '仕事':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '友人':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '奉仕':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。'
                            },
                    },
                    {
                            no: 213,
                            imgUrl: require('@/assets/tarotcard_p13.png'),
                            name: {
                                normal: 'ペンタクルクイーン',
                                reserve: 'ペンタクルクイーン（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '自分':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                'お金':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '知識':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '家族':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '恋愛':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '健康':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '対人':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '相続':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '海外':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '仕事':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '友人':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '奉仕':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。'
                            },
                            ResultReserve: {
                                'テーマ':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '自分':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                'お金':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '知識':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '家族':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '恋愛':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '健康':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '対人':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '相続':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '海外':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '仕事':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '友人':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '奉仕':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。'
                            },
                    },
                    {
                            no: 214,
                            imgUrl: require('@/assets/tarotcard_p14.png'),
                            name: {
                                normal: 'ペンタクルキング',
                                reserve: 'ペンタクルキング（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '自分':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                'お金':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '知識':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '家族':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '恋愛':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '健康':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '対人':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '相続':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '海外':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '仕事':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '友人':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '奉仕':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。'
                            },
                            ResultReserve: {
                                'テーマ':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '自分':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                'お金':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '知識':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '家族':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '恋愛':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '健康':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '対人':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '相続':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '海外':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '仕事':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '友人':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '奉仕':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。'
                            },
                    },
                    {
                            no: 301,
                            imgUrl: require('@/assets/tarotcard_s1.png'),
                            name: {
                                normal: 'ソードエース',
                                reserve: 'ソードエース（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '自分':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                'お金':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '知識':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '家族':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '恋愛':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '健康':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '対人':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '相続':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '海外':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '仕事':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '友人':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '奉仕':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。'
                            },
                            ResultReserve: {
                                'テーマ':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '自分':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                'お金':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '知識':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '家族':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '恋愛':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '健康':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '対人':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '相続':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '海外':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '仕事':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '友人':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '奉仕':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。'
                            },
                    },
                    {
                            no: 302,
                            imgUrl: require('@/assets/tarotcard_s2.png'),
                            name: {
                                normal: 'ソード2',
                                reserve: 'ソード2（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '自分':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                'お金':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '知識':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '家族':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '恋愛':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '健康':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '対人':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '相続':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '海外':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '仕事':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '友人':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '奉仕':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。'
                            },
                            ResultReserve: {
                                'テーマ':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '自分':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                'お金':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '知識':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '家族':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '恋愛':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '健康':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '対人':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '相続':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '海外':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '仕事':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '友人':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '奉仕':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。'
                            },
                    },
                    {
                            no: 303,
                            imgUrl: require('@/assets/tarotcard_s3.png'),
                            name: {
                                normal: 'ソード3',
                                reserve: 'ソード3（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '自分':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                'お金':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '知識':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '家族':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '恋愛':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '健康':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '対人':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '相続':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '海外':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '仕事':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '友人':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '奉仕':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。'
                            },
                            ResultReserve: {
                                'テーマ':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '自分':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                'お金':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '知識':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '家族':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '恋愛':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '健康':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '対人':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '相続':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '海外':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '仕事':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '友人':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '奉仕':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。'
                            },
                    },

                    {
                            no: 304,
                            imgUrl: require('@/assets/tarotcard_s4.png'),
                            name: {
                                normal: 'ソード4',
                                reserve: 'ソード4（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '自分':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                'お金':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '知識':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '家族':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '恋愛':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '健康':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '対人':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '相続':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '海外':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '仕事':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '友人':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '奉仕':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。'
                            },
                            ResultReserve: {
                                'テーマ':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '自分':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                'お金':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '知識':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '家族':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '恋愛':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '健康':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '対人':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '相続':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '海外':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '仕事':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '友人':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '奉仕':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。'
                            },
                    },

                    {
                            no: 305,
                            imgUrl: require('@/assets/tarotcard_s5.png'),
                            name: {
                                normal: 'ソード5',
                                reserve: 'ソード5（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '自分':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                'お金':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '知識':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '家族':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '恋愛':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '健康':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '対人':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '相続':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '海外':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '仕事':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '友人':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '奉仕':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。'
                            },
                            ResultReserve: {
                                'テーマ':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '自分':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                'お金':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '知識':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '家族':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '恋愛':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '健康':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '対人':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '相続':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '海外':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '仕事':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '友人':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '奉仕':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。'
                            },
                    },

                    {
                            no: 306,
                            imgUrl: require('@/assets/tarotcard_s6.png'),
                            name: {
                                normal: 'ソード6',
                                reserve: 'ソード6（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '自分':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                'お金':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '知識':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '家族':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '恋愛':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '健康':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '対人':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '相続':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '海外':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '仕事':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '友人':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '奉仕':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '自分':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                'お金':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '知識':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '家族':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '恋愛':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '健康':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '対人':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '相続':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '海外':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '仕事':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '友人':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '奉仕':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。'
                            },
                    },

                    {
                            no: 307,
                            imgUrl: require('@/assets/tarotcard_s7.png'),
                            name: {
                                normal: 'ソード7',
                                reserve: 'ソード7（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '自分':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                'お金':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '知識':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '家族':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '恋愛':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '健康':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '対人':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '相続':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '海外':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '仕事':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '友人':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '奉仕':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。'
                            },
                            ResultReserve: {
                                'テーマ':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '自分':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                'お金':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '知識':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '家族':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '恋愛':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '健康':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '対人':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '相続':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '海外':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '仕事':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '友人':'',
                                '奉仕':''
                            },
                    },

                    {
                            no: 308,
                            imgUrl: require('@/assets/tarotcard_s8.png'),
                            name: {
                                normal: 'ソード8',
                                reserve: 'ソード8（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '自分':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                'お金':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '知識':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '家族':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '恋愛':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '健康':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '対人':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '相続':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '海外':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '仕事':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '友人':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '奉仕':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。'
                            },
                            ResultReserve: {
                                'テーマ':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '自分':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                'お金':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '知識':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '家族':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '恋愛':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '健康':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '対人':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '相続':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '海外':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '仕事':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '友人':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '奉仕':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。'
                            },
                    },

                    {
                            no: 309,
                            imgUrl: require('@/assets/tarotcard_s9.png'),
                            name: {
                                normal: 'ソード9',
                                reserve: 'ソード9（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '自分':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                'お金':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '知識':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '家族':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '恋愛':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '健康':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '対人':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '相続':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '海外':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '仕事':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '友人':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '奉仕':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。'
                            },
                            ResultReserve: {
                                'テーマ':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '自分':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                'お金':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '知識':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '家族':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '恋愛':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '健康':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '対人':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '相続':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '海外':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '仕事':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '友人':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '奉仕':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。'
                            },
                    },

                    {
                            no: 310,
                            imgUrl: require('@/assets/tarotcard_s10.png'),
                            name: {
                                normal: 'ソード10',
                                reserve: 'ソード10（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '自分':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                'お金':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '知識':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '家族':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '恋愛':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '健康':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '対人':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '相続':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '海外':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '仕事':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '友人':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '奉仕':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。'
                            },
                            ResultReserve: {
                                'テーマ':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '自分':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                'お金':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '知識':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '家族':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '恋愛':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '健康':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '対人':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '相続':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '海外':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '仕事':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '友人':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '奉仕':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。'
                            },
                    },

                    {
                            no: 311,
                            imgUrl: require('@/assets/tarotcard_s11.png'),
                            name: {
                                normal: 'ソードペイジ',
                                reserve: 'ソードペイジ（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '自分':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                'お金':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '知識':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '家族':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '恋愛':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '健康':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '対人':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '相続':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '海外':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '仕事':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '友人':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '奉仕':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。'
                            },
                            ResultReserve: {
                                'テーマ':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '自分':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                'お金':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '知識':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '家族':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '恋愛':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '健康':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '対人':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '相続':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '海外':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '仕事':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '友人':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '奉仕':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。'
                            },
                    },

                    {
                            no: 312,
                            imgUrl: require('@/assets/tarotcard_s12.png'),
                            name: {
                                normal: 'ソードナイト',
                                reserve: 'ソードナイト（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '自分':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                'お金':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '知識':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '家族':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '恋愛':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '健康':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '対人':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '相続':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '海外':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '仕事':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '友人':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '奉仕':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                                '自分':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                                'お金':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                                '知識':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                                '家族':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                                '恋愛':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性の強引なアプローチなどで振り回されそうなので注意してください。',
                                '健康':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性の強引なアプローチなどで振り回されそうなので注意してください。',
                                '対人':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性の強引なアプローチなどで振り回されそうなので注意してください。',
                                '相続':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性の強引なアプローチなどで振り回されそうなので注意してください。',
                                '海外':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                                '仕事':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                                '友人':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                                '奉仕':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。'
                            },
                    },

                    {
                            no: 313,
                            imgUrl: require('@/assets/tarotcard_s13.png'),
                            name: {
                                normal: 'ソードクイーン',
                                reserve: 'ソードクイーン（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '自分':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                'お金':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '知識':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '家族':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '恋愛':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '健康':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '対人':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '相続':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '海外':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '仕事':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '友人':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '奉仕':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '自分':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                'お金':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '知識':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '家族':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '恋愛':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '健康':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '対人':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '相続':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '海外':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '仕事':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '友人':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '奉仕':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。'
                            },
                    },

                    {
                            no: 314,
                            imgUrl: require('@/assets/tarotcard_s14.png'),
                            name: {
                                normal: 'ソードキング',
                                reserve: 'ソードキング（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '自分':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                'お金':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '知識':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '家族':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '恋愛':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '健康':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '対人':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '相続':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '海外':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '仕事':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '友人':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '奉仕':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。'
                            },
                            ResultReserve: {
                                'テーマ':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '自分':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                'お金':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '知識':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '家族':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '恋愛':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '健康':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '対人':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '相続':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '海外':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '仕事':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '友人':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '奉仕':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。'
                            },
                    },

                    {
                            no: 401,
                            imgUrl: require('@/assets/tarotcard_c1.png'),
                            name: {
                                normal: 'カップエース',
                                reserve: 'ソードエース（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '自分':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                'お金':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '知識':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '家族':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '恋愛':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '健康':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '対人':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '相続':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '海外':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '仕事':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '友人':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '奉仕':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。'
                            },
                            ResultReserve: {
                                'テーマ':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '自分':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                'お金':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '知識':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '家族':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '恋愛':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '健康':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '対人':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '相続':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '海外':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '仕事':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '友人':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '奉仕':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。'
                            },
                    },
                    {
                            no: 402,
                            imgUrl: require('@/assets/tarotcard_c2.png'),
                            name: {
                                normal: 'カップ2',
                                reserve: 'カップ2（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '自分':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                'お金':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '知識':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '家族':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '恋愛':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '健康':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '対人':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '相続':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '海外':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '仕事':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '友人':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '奉仕':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '自分':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                'お金':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '知識':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '家族':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '恋愛':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '健康':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '対人':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '相続':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '海外':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '仕事':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '友人':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '奉仕':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。'
                            },
                    },
                    {
                            no: 403,
                            imgUrl: require('@/assets/tarotcard_c3.png'),
                            name: {
                                normal: 'カップ3',
                                reserve: 'カップ3（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '自分':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                'お金':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '知識':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '家族':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '恋愛':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '健康':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '対人':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '相続':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '海外':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '仕事':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '友人':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '奉仕':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。'
                            },
                            ResultReserve: {
                                'テーマ':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '自分':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                'お金':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '知識':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '家族':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '恋愛':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '健康':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '対人':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '相続':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '海外':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '仕事':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '友人':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '奉仕':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。'
                            },
                    },
                    {
                            no: 404,
                            imgUrl: require('@/assets/tarotcard_c4.png'),
                            name: {
                                normal: 'カップ4',
                                reserve: 'カップ4（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '自分':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                'お金':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '知識':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '家族':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '恋愛':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '健康':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '対人':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '相続':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '海外':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '仕事':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '友人':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '奉仕':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '自分':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                'お金':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '知識':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '家族':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '恋愛':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '健康':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '対人':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '相続':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '海外':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '仕事':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '友人':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '奉仕':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。'
                            },
                    },
                    {
                            no: 405,
                            imgUrl: require('@/assets/tarotcard_c5.png'),
                            name: {
                                normal: 'カップ5',
                                reserve: 'カップ5（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '自分':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                'お金':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '知識':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '家族':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '恋愛':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '健康':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '対人':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '相続':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '海外':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '仕事':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '友人':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '奉仕':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。'
                            },
                            ResultReserve: {
                                'テーマ':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '自分':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                'お金':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '知識':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '家族':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '恋愛':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '健康':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '対人':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '相続':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '海外':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '仕事':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '友人':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '奉仕':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。'
                            },
                    },
                    {
                            no: 406,
                            imgUrl: require('@/assets/tarotcard_c6.png'),
                            name: {
                                normal: 'カップ6',
                                reserve: 'カップ6（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '自分':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                'お金':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '知識':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '家族':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '恋愛':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '健康':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '対人':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '相続':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '海外':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '仕事':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '友人':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '奉仕':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。'
                            },
                            ResultReserve: {
                                'テーマ':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '自分':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                'お金':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '知識':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '家族':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '恋愛':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '健康':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '対人':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '相続':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '海外':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '仕事':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '友人':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '奉仕':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。'
                            },
                    },
                    {
                            no: 407,
                            imgUrl: require('@/assets/tarotcard_c7.png'),
                            name: {
                                normal: 'カップ7',
                                reserve: 'カップ7（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '自分':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                'お金':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '知識':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '家族':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '恋愛':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '健康':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '対人':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '相続':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '海外':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '仕事':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '友人':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '奉仕':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。'
                            },
                            ResultReserve: {
                                'テーマ':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '自分':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                'お金':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '知識':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '家族':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '恋愛':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '健康':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '対人':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '相続':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '海外':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '仕事':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '友人':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '奉仕':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。'
                            },
                    },
                    {
                            no: 408,
                            imgUrl: require('@/assets/tarotcard_c8.png'),
                            name: {
                                normal: 'カップ8',
                                reserve: 'カップ8（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '自分':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                'お金':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '知識':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '家族':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '恋愛':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '健康':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '対人':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '相続':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '海外':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '仕事':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '友人':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '奉仕':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。'
                            },
                            ResultReserve: {
                                'テーマ':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '自分':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                'お金':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '知識':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '家族':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '恋愛':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '健康':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '対人':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '相続':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '海外':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '仕事':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '友人':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '奉仕':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。'
                            },
                    },
                    {
                            no: 409,
                            imgUrl: require('@/assets/tarotcard_c9.png'),
                            name: {
                                normal: 'カップ9',
                                reserve: 'カップ9（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。ただし慢心は禁物です。',
                                '自分':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                'お金':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '知識':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '家族':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '恋愛':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '健康':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '対人':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '相続':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '海外':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '仕事':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '友人':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '奉仕':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '自分':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                'お金':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '知識':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '家族':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '恋愛':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '健康':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '対人':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '相続':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '海外':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '仕事':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '友人':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '奉仕':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。'
                            },
                    },
                    {
                            no: 410,
                            imgUrl: require('@/assets/tarotcard_c10.png'),
                            name: {
                                normal: 'カップ10',
                                reserve: 'カップ10（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '自分':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                'お金':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '知識':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '家族':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '恋愛':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '健康':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '対人':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '相続':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '海外':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '仕事':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '友人':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '奉仕':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。'
                            },
                            ResultReserve: {
                                'テーマ':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '自分':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                'お金':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '知識':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '家族':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '恋愛':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '健康':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '対人':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '相続':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '海外':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '仕事':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '友人':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '奉仕':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。'
                            },
                    },
                    {
                            no: 411,
                            imgUrl: require('@/assets/tarotcard_c11.png'),
                            name: {
                                normal: 'カップペイジ',
                                reserve: 'カップペイジ（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '自分':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                'お金':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '知識':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '家族':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '恋愛':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '健康':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '対人':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '相続':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '海外':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '仕事':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '友人':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '奉仕':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '自分':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                'お金':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '知識':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '家族':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '恋愛':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '健康':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '対人':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '相続':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '海外':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '仕事':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '友人':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '奉仕':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。'
                            },
                    },
                    {
                            no: 412,
                            imgUrl: require('@/assets/tarotcard_c12.png'),
                            name: {
                                normal: 'カップナイト',
                                reserve: 'カップナイト（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '自分':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                'お金':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '知識':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '家族':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '恋愛':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '健康':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '対人':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '相続':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '海外':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '仕事':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '友人':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '奉仕':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '自分':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                'お金':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '知識':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '家族':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '恋愛':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '健康':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '対人':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '相続':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '海外':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '仕事':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '友人':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '奉仕':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。'
                            },
                    },
                    {
                            no: 413,
                            imgUrl: require('@/assets/tarotcard_c13.png'),
                            name: {
                                normal: 'カップクイーン',
                                reserve: 'カップクイーン（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '自分':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                'お金':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '知識':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '家族':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '恋愛':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '健康':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '対人':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '相続':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '海外':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '仕事':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '友人':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '奉仕':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。'
                            },
                            ResultReserve: {
                                'テーマ':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '自分':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                'お金':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '知識':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '家族':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '恋愛':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '健康':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '対人':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '相続':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '海外':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '仕事':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '友人':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '奉仕':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。'
                            },
                    },
                    {
                            no: 414,
                            imgUrl: require('@/assets/tarotcard_c14.png'),
                            name: {
                                normal: 'カップキング',
                                reserve: 'カップキング（逆位置）'
                            },
                            ResultNormal: {
                                'テーマ':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '自分':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                'お金':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '知識':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '家族':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '恋愛':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '健康':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '対人':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '相続':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '海外':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '仕事':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '友人':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '奉仕':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。'
                            },
                            ResultReserve: {
                                'テーマ':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '自分':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                'お金':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '知識':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '家族':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '恋愛':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '健康':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '対人':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '相続':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '海外':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '仕事':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '友人':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '奉仕':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。'
                            },
                    },
                ],
            }
        },
        methods:{
            change:function(){
                this.isActive = !this.isActive;
            },
            change1:function(){
                this.CardIsActive1 = !this.CardIsActive1;
            },
            change2:function(){
                this.CardIsActive2 = !this.CardIsActive2;
            },
            change3:function(){
                this.CardIsActive3 = !this.CardIsActive3;
            },
            change4:function(){
                this.CardIsActive4 = !this.CardIsActive4;
            },
            change5:function(){
                this.CardIsActive5 = !this.CardIsActive5;
            },
            change6:function(){
                this.CardIsActive6 = !this.CardIsActive6;
            },
            change7:function(){
                this.CardIsActive7 = !this.CardIsActive7;
            },
            change8:function(){
                this.CardIsActive8 = !this.CardIsActive8;
            },
            change9:function(){
                this.CardIsActive9 = !this.CardIsActive9;
            },
            change10:function(){
                this.CardIsActive10 = !this.CardIsActive10;
            },
            change11:function(){
                this.CardIsActive11 = !this.CardIsActive11;
            },
            change12:function(){
                this.CardIsActive12 = !this.CardIsActive12;
            },
            change13:function(){
                this.CardIsActive13 = !this.CardIsActive13;
            },
            btnActive: function(){
                const n = this.turn;
                if ( n == 0 ) {
                    this.isDisabled = !this.isDisabled;
                    this.SnackIsActive = !this.SnackIsActive;
                    this.turn++;
                    return true;
                }
                else {
                    return false;
                }
            },
            ramdam1: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl1 = this.imgsUrl1;
                this.imgsUrl1 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve1 = this.cardReserve1;
                if ( n == 1 ){
                    this.cardReserve1 = 'transform:rotate(0deg)';
                    this.cardText1 = this.imgsCard[imageNo]['ResultNormal']['テーマ'];
                    this.cardName1 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve1 = 'transform:rotate(180deg)';
                    this.cardText1 = this.imgsCard[imageNo]['ResultReserve']['テーマ'];
                    this.cardName1 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam2: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl2 = this.imgsUrl2;
                this.imgsUrl2 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve2 = this.cardReserve2;
                if ( n == 1 ){
                    this.cardReserve2 = 'transform:rotate(0deg)';
                    this.cardText2 = this.imgsCard[imageNo]['ResultNormal']['自分'];
                    this.cardName2 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve2 = 'transform:rotate(180deg)';
                    this.cardText2 = this.imgsCard[imageNo]['ResultReserve']['自分'];
                    this.cardName2 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam3: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl3 = this.imgsUrl3;
                this.imgsUrl3 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve3 = this.cardReserve3;
                if ( n == 1 ){
                    this.cardReserve3 = 'transform:rotate(0deg)';
                    this.cardText3 = this.imgsCard[imageNo]['ResultNormal']['お金'];
                    this.cardName3 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve3 = 'transform:rotate(180deg)';
                    this.cardText3 = this.imgsCard[imageNo]['ResultReserve']['お金'];
                    this.cardName3 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam4: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl4 = this.imgsUrl4;
                this.imgsUrl4 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve4 = this.cardReserve4;
                if ( n == 1 ){
                    this.cardReserve4 = 'transform:rotate(0deg)';
                    this.cardText4 = this.imgsCard[imageNo]['ResultNormal']['知識'];
                    this.cardName4 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve4 = 'transform:rotate(180deg)';
                    this.cardText4 = this.imgsCard[imageNo]['ResultReserve']['知識'];
                    this.cardName4 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam5: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl5 = this.imgsUrl5;
                this.imgsUrl5 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve5 = this.cardReserve3;
                if ( n == 1 ){
                    this.cardReserve5 = 'transform:rotate(0deg)';
                    this.cardText5 = this.imgsCard[imageNo]['ResultNormal']['家族'];
                    this.cardName5 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve5 = 'transform:rotate(180deg)';
                    this.cardText5 = this.imgsCard[imageNo]['ResultReserve']['家族'];
                    this.cardName5 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam6: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl6 = this.imgsUrl6;
                this.imgsUrl6 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve6 = this.cardReserve6;
                if ( n == 1 ){
                    this.cardReserve6 = 'transform:rotate(0deg)';
                    this.cardText6 = this.imgsCard[imageNo]['ResultNormal']['恋愛'];
                    this.cardName6 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve6 = 'transform:rotate(180deg)';
                    this.cardText6 = this.imgsCard[imageNo]['ResultReserve']['恋愛'];
                    this.cardName6 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam7: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl7 = this.imgsUrl7;
                this.imgsUrl7 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve7 = this.cardReserve3;
                if ( n == 1 ){
                    this.cardReserve7 = 'transform:rotate(0deg)';
                    this.cardText7 = this.imgsCard[imageNo]['ResultNormal']['健康'];
                    this.cardName7 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve7 = 'transform:rotate(180deg)';
                    this.cardText7 = this.imgsCard[imageNo]['ResultReserve']['健康'];
                    this.cardName7 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam8: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl8 = this.imgsUrl8;
                this.imgsUrl8 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve8 = this.cardReserve8;
                if ( n == 1 ){
                    this.cardReserve8 = 'transform:rotate(0deg)';
                    this.cardText8 = this.imgsCard[imageNo]['ResultNormal']['対人'];
                    this.cardName8 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve8 = 'transform:rotate(180deg)';
                    this.cardText8 = this.imgsCard[imageNo]['ResultReserve']['対人'];
                    this.cardName8 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam9: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl9 = this.imgsUrl9;
                this.imgsUrl9 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve9 = this.cardReserve9;
                if ( n == 1 ){
                    this.cardReserve9 = 'transform:rotate(0deg)';
                    this.cardText9 = this.imgsCard[imageNo]['ResultNormal']['相続'];
                    this.cardName9 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve9 = 'transform:rotate(180deg)';
                    this.cardText9 = this.imgsCard[imageNo]['ResultReserve']['相続'];
                    this.cardName9 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam10: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl10 = this.imgsUrl10;
                this.imgsUrl10 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve10 = this.cardReserve10;
                if ( n == 1 ){
                    this.cardReserve10 = 'transform:rotate(0deg)';
                    this.cardText10 = this.imgsCard[imageNo]['ResultNormal']['海外'];
                    this.cardName10 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve10 = 'transform:rotate(180deg)';
                    this.cardText10 = this.imgsCard[imageNo]['ResultReserve']['海外'];
                    this.cardName10 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam11: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl11 = this.imgsUrl11;
                this.imgsUrl11 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve11 = this.cardReserve11;
                if ( n == 1 ){
                    this.cardReserve11 = 'transform:rotate(0deg)';
                    this.cardText11 = this.imgsCard[imageNo]['ResultNormal']['仕事'];
                    this.cardName11 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve11 = 'transform:rotate(180deg)';
                    this.cardText11 = this.imgsCard[imageNo]['ResultReserve']['仕事'];
                    this.cardName11 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam12: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl12 = this.imgsUrl12;
                this.imgsUrl12 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve12 = this.cardReserve12;
                if ( n == 1 ){
                    this.cardReserve12 = 'transform:rotate(0deg)';
                    this.cardText12 = this.imgsCard[imageNo]['ResultNormal']['友人'];
                    this.cardName12 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve12 = 'transform:rotate(180deg)';
                    this.cardText12 = this.imgsCard[imageNo]['ResultReserve']['友人'];
                    this.cardName12 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam13: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl13 = this.imgsUrl13;
                this.imgsUrl13 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve13 = this.cardReserve13;
                if ( n == 1 ){
                    this.cardReserve13 = 'transform:rotate(0deg)';
                    this.cardText13 = this.imgsCard[imageNo]['ResultNormal']['奉仕'];
                    this.cardName13 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve13 = 'transform:rotate(180deg)';
                    this.cardText13 = this.imgsCard[imageNo]['ResultReserve']['奉仕'];
                    this.cardName13 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
        }
    }  
</script>

<style scoped>
    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    button{
        background-color: transparent;
    }
    .page-title {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 1.7;
        background: #ffffff73;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 12px;
        max-width: 600px;
        margin: 0 auto;
    }
  .divination {
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }
  .cardchoice-area {
    margin-bottom: 20px;
  }
.card-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    height: 20rem;
    width: 20rem;
    margin: 20px auto 60px;
}
.card-list li {
    position: absolute;
    height: 3rem;
    margin: auto;
}
.card-list li:nth-of-type(1) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
}
.card-list li:nth-of-type(2) {
    top: 0;
    bottom: 0;
    left: 0.3rem;
}
.card-list li:nth-of-type(3) {
    top: 6rem;
    bottom: 0;
    left: 3.2rem;
}
.card-list li:nth-of-type(4) {
    top: 11rem;
    bottom: 0;
    left: 6rem;
}
.card-list li:nth-of-type(5) {
    bottom: 0.5rem;
    left: 0;
    right: 0;
    width: 20%;
}
.card-list li:nth-of-type(6) {
    top: 11rem;
    bottom: 0;
    right: 6rem;
}
.card-list li:nth-of-type(7) {
    top: 6rem;
    bottom: 0;
    right: 3.2rem;
}
.card-list li:nth-of-type(8) {
    top: 0;
    bottom: 0;
    right: 0.3rem;
}
.card-list li:nth-of-type(9) {
    top: -6rem;
    bottom: 0;
    right: 3.2rem;
}
.card-list li:nth-of-type(10) {
    top: -11rem;
    bottom: 0;
    right: 6rem;
}
.card-list li:nth-of-type(11) {
    top: 0.5rem;
    left: 0;
    right: 0;
    width: 20%;
}
.card-list li:nth-of-type(12) {
    top: -11rem;
    bottom: 0;
    left: 6rem;
}
.card-list li:nth-of-type(13) {
    top: -6rem;
    bottom: 0;
    left: 3.2rem;
}
    .card-list img{
        width: 40px;
        height: 70px;
        filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
    }
    .card-list p {
        font-size: 12px;
        line-height: 1.5;
    }
    .card-result {
        display: none;
    }
    .card-result__item {
        display: none;
    }
    .card-result__item1 {
        display: none;
    }
    .card-result li {
        margin-bottom: 16px;
    }
    .card-result dl {
        background: #fff;
        border-radius: 16px;
        padding: 20px;
        max-width: 400px;
        margin: 0 auto;
    }
    .card-result dl dt {
        background: #F4F4F4;
        border-radius: 100px;
        font-weight: bold;
        line-height: 1.7;
        letter-spacing: 2px;
        padding: 6px;
        max-width: 300px;
        margin: 0 auto 20px;
    }
    .card-result__card-name {
        font-weight: bold;
    }
    .card-result dl dd img {
        max-width: 100px;
    }
    @media screen and (min-width:769px) {
        .card-list img {

        }
    }
.mb-1em{
  margin-bottom: 1em;
}
.normal-txt {
    font-size: 14px;
    text-align: left;
}
.snackshow {
    display: none;
}
.resultshow {
  display: block;
}
.resultshow1,
.resultshow2,
.resultshow3,
.resultshow4,
.resultshow5,
.resultshow6,
.resultshow7,
.resultshow8,
.resultshow9,
.resultshow10,
.resultshow11,
.resultshow12,
.resultshow13 {
    display: block;
}
a,
a:visited{
    color: #fff;
}
button:disabled{
    background: #D3D3D3;
}
button:disabled a {
    color: #838383;
}
button a {
    display: inline-block;
    width: 100%;
}
</style>